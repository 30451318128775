<template>
  <div>
    <div>

      <el-divider></el-divider>
      <div class="flex">
        <!--      左侧图片-->
        <div class="image_box" @click="viewerShow">
          <el-image style="width: 100%;height: 100%;" :src="designColors[activeColor].img[activeImg].img400"></el-image>
        </div>
        <!--      右侧信息-->
        <div style="flex: 1">
          <div class="title_text ">选择主图</div>
          <div class="flex">
            <div class="select_image" v-for="(item,index) in designColors[activeColor].img" :key="index"
                 @click="setImg(item,index)">
              <div class="select_image_radio">
                <el-radio v-model="info.radio" :label="item.original"></el-radio>
              </div>
              <el-image :src="item.img400"></el-image>
            </div>
          </div>
          <div class="flex">
            <div
                class="color_box"
                v-for="(item,index) in designColors"
                :key="item.id"
                :class="{'active_color':index === activeColor}"
                @click="setColor(item,index)"
                :style="{'background':'#' + item.color}"
            >
            </div>
          </div>


          <div class="title_text mt_20">模板名称</div>
          <div>
            <el-input style="width: 500px;" v-model="info.name" placeholder="请输入名称"></el-input>
          </div>
          <div class="title_text mt_20">标签</div>
          <div>
            <el-input style="width: 500px;" v-model="info.tags" placeholder="请输入标签"></el-input>
          </div>
          <div class="flex">

            <div style="margin-right: 30px">
              <div class="title_text mt_20">建议零售价</div>
              <el-input-number v-model="info.suggestedRetailPrice" controls-position="right" :min="0"
                               :precision="2"></el-input-number>
            </div>

            <div>
              <div class="title_text mt_20">市场价</div>
              <el-input-number v-model="info.marketPrice" controls-position="right" :min="0"
                               :precision="2"></el-input-number>
            </div>
          </div>
          <!--        <div class="title_text mt_20">模板分类</div>-->
          <!--        <div>-->
          <!--          <el-select placeholder="请选择活动区域">-->
          <!--            <el-option label="区域一" value="shanghai"></el-option>-->
          <!--            <el-option label="区域二" value="beijing"></el-option>-->
          <!--          </el-select>-->
          <!--        </div>-->
          <div class="title_text mt_20">产品详情描述</div>
          <div id="editor">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import compositing from '@/assets/compositing.png'
import E from 'wangeditor'
import {verifyImageMd5, saveImage, ossStsConfig} from '@/api/map.js';
import OSS from "ali-oss";
import CryptoJS from "crypto-js";
import UUID from 'uuidjs'

import {mapState} from "vuex";

export default {
  name: "basicInformation",
  props: {
    finishedProductDetails: {
      type: Object,
      default: () => ({})
    },
    info: {
      type: Object,
      default: () => ({})
    },
    designColors: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    compositing,
    activeColor: 0,
    activeImg: 0,
    ossClient: null
  }),
  computed: {
    ...mapState(['userInfo']),
  },
  watch: {},
  mounted() {
    this.setEditor()
  },
  methods: {
    //预览图
    viewerShow() {
      let arr = []
      this.designColors[this.activeColor].img.forEach(e => {
        arr.push(e.img)
      })
      this.$viewerApi({
        options: {
          initialViewIndex: this.activeImg
        },
        images: arr,
      })
    },


    //富文本初始化
    setEditor() {
      const editor = new E('#editor')
      editor.config.menus = [
        'redo',
        'undo',
        'bold',
        'head',
        'link',
        'italic',
        'underline',
        'table',
        'emoticon',
        'justify',
        'list',
        'link',
        'lineHeight',
        'indent',
        'strikeThrough',
        'fontSize',
        'image'
      ]
      editor.config.pasteFilterStyle = false
      editor.config.pasteIgnoreImg = false
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png'] //限制图片类型
      editor.config.onchangeTimeout = 10000; // 修改为 500ms
      editor.config.zIndex = 10;
      editor.config.uploadImgServer = ' '
      editor.config.uploadImgMaxLength = 1 // 上传限制数量

      console.log(editor.config)







      //上传图片
      this.editorUploadImg(editor)
      this.editorChange(editor)


      editor.create()
      editor.txt.html(this.info.describe)
    },
    //上传图片
    async editorUploadImg(editor) {


      editor.config.customUploadImg = async (resultFiles, insertImgFn) => {

        const { type } = resultFiles[0]
        let list = ['image/jpeg','image/png','image/jpg']

        if(!(list.includes(type))) {
          this.$message.error('上传图片仅支持 JPEG | PNG | JPG')
          return false
        }

        let userInfoID = this.userInfo.merchant[0].id
        const baseUrl = String(process.env.VUE_APP_OSS_BASEURL)
        const baseHttpPrefix = String(process.env.VUE_APP_OSS_BASE_HTTP_PREFIX);
        if (!this.ossClient) {
          //1.获取阿里云配置
          const {data} = await ossStsConfig()
          const {AccessKeyId, AccessKeySecret, SecurityToken} = data.Credentials

          //2.初始化阿里云client
          this.ossClient = new OSS({
            region: 'oss-cn-shenzhen',
            accessKeyId: AccessKeyId,
            accessKeySecret: AccessKeySecret,
            stsToken: SecurityToken,
            bucket: process.env.VUE_APP_OSS_BUCKET_NAME,
            refreshSTSToken: async () => {
              const {data} = await ossStsConfig()
              return {
                accessKeyId: data.AccessKeyId,
                accessKeySecret: data.AccessKeySecret,
                stsToken: data.SecurityToken,
              }
            }
          })
        }

        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        let name = resultFiles[0].name
        const base64 = await this.fileByBase64(resultFiles[0])
        const imageMd5 = CryptoJS.MD5(base64).toString()
        await this.ossClient.multipartUpload(
            baseUrl + userInfoID + '/' + imageMd5 + name.substring(name.lastIndexOf('.')),
            resultFiles[0],
        )

        await setTimeout(()=>{
         const url = baseHttpPrefix + baseUrl + userInfoID + '/' + imageMd5 + name.substring(name.lastIndexOf('.'))
         insertImgFn(url)
       })
      }


    },
    //转化base64
    async fileByBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          // target.result 该属性表示目标对象的DataURL
          resolve(e.target.result)
        };
      })
    },
    //输入完回调
    editorChange(editor) {
      editor.config.onchange = (newHtml) => {
        this.info.describe = newHtml
        console.log(newHtml)
      };
    },
    setColor(item, index) {
      this.info.designColorId = item.id
      this.activeColor = index
      this.activeImg = 0
      this.$emit('setRatio', item.img[this.activeImg].original)
    },
    setImg(item, index) {

      this.activeImg = index
      this.$emit('setRatio', item.original)
    }
  }
}
</script>

<style scoped lang='less'>

.active_color {
  border: 1px solid #FA4A14 !important;
}

/deep/ .el-divider--horizontal {
  margin-top: 10px;
}

/deep/ .el-radio__label {
  display: none;
}


.color_box {
  width: 30px;
  height: 30px;
  margin-top: 20px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #eee;
}

#editor {
  max-width: 1000px;
}

.flex {
  display: flex;
}

.mt_20 {
  margin-top: 20px;
}


.image_box {
  width: 208px;
  height: 208px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-right: 40px;
  cursor: pointer;
}

.title_text {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
}

.select_image {
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-right: 20px;
  position: relative;
  overflow: hidden;

  &_radio {
    position: absolute;
    top: 5px;
    left: 5px;
    z-index: 9;
  }
}

</style>