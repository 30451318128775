<template>
  <div>
    <el-dialog
        title="类别归类"
        :visible.sync="BatchShowClass"
        width="527px"
        :before-close="handleClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item label="类别" prop="sort">
          <el-select v-model="ruleForm.sort" placeholder="请选择类别" >
            <el-option v-for="item in sortlist" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="sure">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {getThemeList} from '@/api/design'

export default {
  props: {
    BatchShowClass: {
      type: Boolean
    }
  },
  data() {
    return {
      ruleForm: {
        sort: null
      },
      rules: {
        sort: [{required: true, message: '请选择类别', trigger: 'blur'},]
      },
      sortlist: [],  //分类列表
    }
  },
  created() {

  },
  mounted() {
    this.getThemeList()
  },
  methods: {
    handleClose() {
      this.$emit('close', false)
      this.ruleForm.sort = null
      this.$refs.ruleForm.resetFields();
    },
    // 获取类别
    async getThemeList() {
      try {
        const { data } = await getThemeList()
        this.sortlist = data

      } catch (e) {
        this.$message.error(e)
      }
    },

    // 确认
    sure() {
      this.$refs.ruleForm.validate(val => {
        if (val) {
          this.$emit('sort', this.ruleForm.sort)
          this.handleClose()
        }
      })

    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  // height: 571px;
  background: #FFFFFF;
  box-shadow: 0px 6px 16px 4px rgba(51, 51, 51, 0.3);
  border-radius: 10px;
  padding: 0 20px;

  .el-dialog__header {
    // margin: 0 20px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid #d8d8d8;
  }

  .el-dialog__body {
    padding: 20px 10px;
    // border-bottom: 1px solid #d8d8d8;
  }
}

/deep/ .el-form-item {
  display: flex;

  .el-form-item__label {
    width: 70px;
  }

  .el-form-item__content {
    flex: 1;

    .el-select {
      width: 100%;
    }
  }
}
</style>
