<template>
  <div>
    <div class="basicInformation">规格信息</div>
    <el-divider></el-divider>
    <div class="btn_box">
      <el-button type="primary" @click="modifyThePrice" :disabled="!multipleSelectionStatus">修改价格</el-button>
      <el-button type="primary" @click="modifyProfit" :disabled="!multipleSelectionStatus">修改利润</el-button>
      <el-button type="primary" @click="modifyProfitMargin" :disabled="!multipleSelectionStatus">修改利润率</el-button>
    </div>
    <el-table
        ref="multipleTable"
        :data="designColors"
        tooltip-effect="dark"
        style="width: 100%;text-align: center"
        @select="select"
        @select-all="selectAll"
        row-key="id"
        :tree-props="{children: 'design_item', hasChildren: 'hasChildren'}"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          label="id"
          width="100">
        <template v-if="!!scope.row.size_name" slot-scope="scope">{{ scope.row.id }}</template>
      </el-table-column>
      <el-table-column
          label="SKU"
          width="200">
        <template slot-scope="scope">{{ scope.row.code }}</template>
      </el-table-column>
      <el-table-column
          prop="name"
          label="规格"
          width="120">
        <template slot-scope="{ row }">
          <div>{{ row.size_name ? row.size_name : row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="costPrice"
          label="成本价"
          show-overflow-tooltip
          min-width="120"
      >
      </el-table-column>
      <el-table-column
          prop="retailPrice"
          label="市场价"
          show-overflow-tooltip
          min-width="180">
        <template slot-scope="{ row }">
          <el-input
              v-if="!!row.size_name"
              type="number"
              :min="0" :precision="2"
              v-model="row.msrp"
              @blur="blurNumber(row)"
          >
            <template slot="prepend">￥</template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
          prop="retailPrice"
          label="零售价"
          show-overflow-tooltip
          min-width="180">
        <template slot-scope="{ row }">
          <el-input
              v-if="!!row.size_name"
              type="number"
              :min="0" :precision="2"
              v-model="row.price"
              @blur="blurMsrp(row)"
          >
            <template slot="prepend">￥</template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
          prop="profit"
          label="利润"
          show-overflow-tooltip
          min-width="180">
        <template slot-scope="{ row }">
          <el-input
              v-if="!!row.size_name"
              type="number"
              :min="0" :precision="2"
              v-model="row.profit"
              @blur="blurProfit(row)">
            <template slot="prepend">￥</template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
          prop="profitMargin"
          label="利润率"
          show-overflow-tooltip
          min-width="180">
        <template slot-scope="{ row }">
          <el-input
              v-if="!!row.size_name"
              type="number"
              :min="0" :precision="2"
              v-model="row.profitMargin"
              @blur="blurProfitMargin(row)">
            <template slot="append">%</template>
          </el-input>
        </template>
      </el-table-column>
    </el-table>
    <modify @handleClose="handleClose" :modifyShow="modifyShow" :title="title" @modifyTheValue="modifyTheValue"/>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import Modify from "./modify";

export default {
  name: "sizeTable",
  components: {Modify},
  props: {
    designColors: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    multipleSelection: [],
    modifyShow:false,
    title:''
  }),
  computed: {
    multipleSelectionStatus(){
      return this.multipleSelection.length > 0
    }
  },
  watch: {},
  mounted() {
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    select(selection, row){
      this.$nextTick(()=>{
        if(!!row.design_item) {
          //父级
          let father = selection.find(e=>e.id === row.id)
          if(!!father) {
            father.design_item.forEach(e=>{
              this.$refs.multipleTable.toggleRowSelection(e,true);
            })
          } else {
            selection.forEach(e=>{
              if(e.color_id === row.id) {
                this.$refs.multipleTable.toggleRowSelection(e,false);
              }
            })

          }

        } else {
          //子集
        }
      })
    },
    selectAll(val){
      let falg = false
      val.forEach(e=>{
        if(!!e.design_item && Array.isArray(e.design_item) && e.design_item.length > 0) {
          falg = true
          e.design_item.forEach(v=>{
            this.$refs.multipleTable.toggleRowSelection(v,true);
          })
        }
      })

      if(!falg) {
        this.$refs.multipleTable.clearSelection();
      }
    },

    //modifyTheValue
    modifyTheValue(title,n) {
        if(this.multipleSelection.length > 0) {
          if(title === '价格') {
            this.multipleSelection.forEach(e => {
              if(!e.design_item) {
                e.msrp = n
                this.blurMsrp(e)
              }
            })

          } else if(title === '利润') {
            this.multipleSelection.forEach(e => {
              if(!e.design_item) {
                e.profit = n
                this.blurProfit(e)
              }
            })
          } else if(title === '利润率') {
            this.multipleSelection.forEach(e => {
              if(!e.design_item) {
                e.profitMargin = n
                this.blurProfitMargin(e)
              }
            })
          }
        } else {
          this.$message.error('请先勾选规格')
        }

    },
    //修改价格
    modifyThePrice(){
      this.modifyShow = true
      this.title = '价格'
    },
    //修改利润
    modifyProfit(){
      this.modifyShow = true
      this.title = '利润'

    },
    //修改利润率
    modifyProfitMargin(){
      this.modifyShow = true
      this.title = '利润率'

    },

    handleClose(){
      this.modifyShow = false
    },




    //市场价
    blurNumber(e) {
      e.msrp = Number(e.msrp).toFixed(2)
    },
    //利润率
    blurProfitMargin(e) {
      e.profitMargin = Number(e.profitMargin).toFixed(2)
      //利润
       e.profit = (new BigNumber(e.profitMargin).times(new BigNumber(e.costPrice)) / 100).toFixed(2)
      //零售价
      e.price = new BigNumber(e.profit).plus(new BigNumber(e.costPrice)).toFixed(2)
    },
    //利润
    blurProfit(e) {
      e.profit = Number(e.profit).toFixed(2)
      //零售价
      e.price = (new BigNumber(e.costPrice).plus(new BigNumber(e.profit))).toFixed(2)
      //利润率
      e.profitMargin = (new BigNumber(e.profit).div(new BigNumber(e.costPrice)) * 100).toFixed(2)
    },
    //零售价
    blurMsrp(e) {
      e.price = Number(e.price).toFixed(2)
      //利润
       e.profit = (new BigNumber(e.price).minus(new BigNumber(e.costPrice))).toFixed(2)
      //利润率
      e.profitMargin = (new BigNumber(e.profit).div(new BigNumber(e.costPrice)) * 100).toFixed(2)
    }
  }
}

</script>

<style scoped lang='less'>
/deep/ .el-input__inner {
  padding-right: 0;
}
/deep/.el-table {
  &::before {
    width: 0;
  }
}
/deep/ .el-input-group {
  width: 150px;

}

.basicInformation {
  font-size: 25px;
  font-weight: 600;
  line-height: 50px;
  color: rgba(0, 0, 0, 0.85);
}

.btn_box {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

</style>