<template>
  <div>
    <div class="design-top">
      <!-- 左边 -->
      <div class="left">
        <!-- 产品 -->
        <!-- <div class="product">
          <span class="text">产品</span>
          <ul class="list">
            <li class="product-list" v-for="(item,index) in productlist" :key="index">
              {{item}}
            </li>
          </ul>
          <span class="show-all">显示所有<i class="el-icon-arrow-down"></i></span>
        </div> -->
        <!-- 产品结束 -->
        <!-- 类别 -->
        <div class="sort">

          <ul class="list" v-if="show">
            <li class="text">类别</li>
            <li class="sort-list" v-for="(item,index) in sort" :key="index" :class="{'activer':sortActiver===index}"
                @click="sortDesign(item.id,index)">
              {{ item.name }}
            </li>
            <span class="show-all" v-if="sortlist.length>9" @click="showAll(false)">显示所有<i
                class="el-icon-arrow-down"></i></span>
            <el-button size="mini" class="sort-btn" @click="SortTo">管理类别</el-button>
          </ul>
          <ul class="list" v-else>
            <li class="text">类别</li>
            <li class="sort-list" v-for="(item,index) in sortlist" :key="index" :class="{'activer':sortActiver===index}"
                @click="sortDesign(item.id,index)">
              {{ item.name }}
            </li>
            <span class="show-all" @click="showAll(true)">收起<i class="el-icon-arrow-up"></i></span>
            <el-button size="mini" class="sort-btn" @click="SortTo">管理类别</el-button>
          </ul>
        </div>
        <!-- 类别结束 -->
        <!-- 日期 -->
        <div class="date">

          <ul class="list">
            <li class="text">日期</li>
            <li class="date-list" v-for="(item1,index1) in datelist" :key="index1"
                :class="{'activer':dateActiver===index1}" @click="date(index1,item1)">
              {{ item1 }}
            </li>
          </ul>
        </div>
        <!-- 日期结束 -->

        <div class="date">
          <ul class="list">
            <li class="text">产品类型</li>
            <li class="date-list" v-for="(item,index) in productType" :key="item.id"
                :class="{'activer':index === productTypeActive }" @click="activeType(item)">
              {{ item.name }}
            </li>
          </ul>
        </div>


      </div>
      <!-- 左边结束 -->
      <div class="right">
        <button class="design-btn" @click="design"><i class="el-icon-circle-plus-outline"></i>开始设计</button>
        <!-- <button class="apply-btn">申请模板开发</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import {getThemeList, getMyDesign} from '@/api/design'
import Cookies from "js-cookie";
// import {  }
export default {
  data() {
    return {
      // productlist:['吉尔丹美国标准短袖','吉尔丹美国标准短袖','吉尔丹美国标准短袖','吉尔丹美国标准短袖','吉尔丹美国标准短袖','吉尔丹美国标准短袖','吉尔丹美国标准短袖'],
      sortlist: [{'name': '所有', 'id': 0}],
      sort: [],   //九个默认类别
      datelist: ['所有', '今天', '昨天', '本周', '本月', '上月'],
      sortActiver: 0,  //选中类别
      dateActiver: 0,  //选中日期
      show: true,
      productTypeActive: 0,
      productType: [

        {
          id:0,
          name:'所有'
        },
        {
          id: 1,
          name: '标品'
        },
        {
          id: 2,
          name: '系统模板'
        },
        {
          id: 3,
          name: '定制产品'
        },
        {
          id: 4,
          name: '用户成品'
        }
        ]
    }
  },

  created() {
    this.getThemeList()

  },
  mounted() {
  },
  methods: {

    activeType(item) {
      const { id } = item
      this.productTypeActive = id
      this.$emit('setProductType',id)
    },


    SortTo() {
      // this.$router.push({name: 'mySortTo'})
      this.$emit('mySortToHide')
    },
    // 获取类别
    async getThemeList() {
      try {
        const {data} = await getThemeList()
        this.sortlist.push(...data)
      } catch (e) {
        this.$message.error(e)
      }

      if (this.sortlist.length <= 9) {
        for (let i = 0; i < this.sortlist.length; i++) {
          this.sort.push(this.sortlist[i])
        }
      } else {
        for (let i = 0; i < 9; i++) {
          this.sort.push(this.sortlist[i])
        }
      }
    },
    // 开始设计
    design() {
      let token

      if (process.env.VUE_APP_CUSTOM_ENV === 'production' || process.env.VUE_APP_CUSTOM_ENV === 'test') {

        token = Cookies.get('token')

      } else {

        token = process.env.VUE_APP_TOKEN

      }

      let path = encodeURIComponent(window.location.href)

      // window.location.replace(`${process.env.VUE_APP_DESIGNER_URL}?token=${token}&redirect=${path}`)
      location.href = (`${process.env.VUE_APP_DESIGNER_URL}?token=${token}&redirect=${path}`)
    },
    // 显示所有
    showAll(val) {
      this.show = val
    },
    sortDesign(id, index) {

      this.sortActiver = index
      this.$emit('themeId', id)
    },
    // 获取时间
    date(i, time) {
      this.dateActiver = i

      this.$emit('time', time)
    }
  }
}
</script>

<style scoped lang="less">
ul {
  list-style-type: none;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  display: flex;
  flex-wrap: wrap;
}

.design-top {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 15px;
  // position: relative;
}

.left {
  font-size: 14px;

  div {
    margin-bottom: 16px;
  }
}

.text {
  font-size: 14px;
  margin-right: 20px;
  color: #666666;
}

.activer {
  //  background: #D8D8D8;
  //  border-radius: 4px;
  //  font-size: 12px;
  //  padding: 4px;
  color: #fa4a14;
}

.sort-list {
  margin-right: 20px;
  margin-bottom: 10px;
  //  padding: 4px;
  cursor: pointer;
  user-select: none;
}

.show-all {
  font-size: 14px;
  color: #FA4A14;
  cursor: pointer;
}

.sort-btn {
  width: 80px;
  height: 24px;
  border-radius: 12px;
  // border-radius:14px;
  color: #E94715;
  outline: none;
  border: 1px solid #E94715;
  margin-left: 20px;
  padding: 0;
}

.date-list {
  margin-right: 20px;
  // padding: 4px;
  cursor: pointer;
  user-select: none;
}

.right {
  //  position: absolute;
  //  top: 104px;
  //  right: 62px;
  .design-btn {
    width: 150px;
    height: 40px;
    background: #FA4A14;
    box-shadow: 0px 0px 10px 0px #F93A00;
    border-radius: 5px;
    border: 0px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    font-size: 18px;

    /deep/ .el-icon-circle-plus-outline {
      font-size: 26px;
      margin-right: 5px;
    }
  }

  .apply-btn {
    width: 150px;
    height: 40px;
    outline: none;
    border-radius: 5px;
    border: 1px solid #FA4A14;
    margin-top: 11px;
    font-size: 18px;
    color: #FA4A14;
  }
}


</style>
