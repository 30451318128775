<template>
  <div class="add-shop">
    <el-dialog
        :title="title"
        :visible.sync="Operation"
        width="730px"
        @open="open"
        :before-close="handleClose">

      <div
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="#fff"
      >
        <div class="box" v-if="!!shopId">
          <div class="add-top">
            <div class="title-img">图片</div>
            <div class="title-color">尺寸/颜色</div>
            <div class="title-id">自定义SKU</div>
            <div>操作</div>
          </div>
          <div class="scroll_box">
            <div class="add-body" v-for="(item, index) in shopList" :key="index">
              <div class="add-img">
                <el-image
                    style="width: 100px; height: 100px"
                    fit="cover"
                    :src="item.design_colors.img.length > 0 ? item.design_colors.img[0].img400  : imgNo"></el-image>
                <!--                      :src="item.design_colors.imgThumb[0].img || imgNo"></el-image>-->
              </div>
              <div>{{ item.size_name }}/{{ item.color_name }}</div>
              <div class="add-ID">{{ item.self_sku || '暂无' }}</div>
              <div class="add-operation" @click="add(item)"><i class="el-icon-shopping-cart-2"></i></div>
            </div>
          </div>
        </div>

        <div class="box" v-else>
          <div class="add-top">
            <div class="title-img">图片</div>
            <div class="title-color">颜色</div>
            <div>操作</div>

          </div>
          <div class="scroll_box">
            <div class="add-body" v-for="(item, index) in downloadList" :key="index">
              <div class="add-img">
                <el-image
                    style="width: 100px; height: 100px"
                    fit="cover"
                    :src="item.img.length > 0 ? item.img[0].img400  : imgNo"></el-image>
              </div>
              <div class="add-size ">{{ item.name }}</div>
              <div class="add-operation" v-if="!item.show" @click="download(item)"><i class="el-icon-download"></i>
              </div>
              <div v-else><i class="el-icon-loading "></i></div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getProductsAddCart} from '@/api/products'
import {getCartList} from '@/api/design'
import imgNo from '@/assets/error/imgNo.png'
import {downloadPicturesList} from "../../../../api/design";

import axios from 'axios'
import JSZip from 'jszip'
import FileSaver from 'file-saver'

export default {
  props: {
    Operation: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    shopId: {
      type: Number | String
    },
    downloadId: {
      type: Number | String | Array
    }
  },
  data() {
    return {
      keyword: '',
      color: '',
      shopList: [],
      downloadList: [],
      loading: true,
      imgNo
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
    // 下载
    download(item) {
      this.downloadItem(item)
    },

    handleClose() {
      this.$emit('showShop', false)
      this.shopList = []
      this.downloadList = []
    },

    downloadItem(item) {
      let imgList = []
      console.log(item)
      //不是数组
      if (!!item) {
        item.img.forEach(val => {
          imgList.push(val.img)
        })
      }

      item.show = true
      const zip = new JSZip()
      const cache = {}
      const promises = []
      imgList.forEach(item => {
        const promise = this.getFile(item).then(data => {
          const arr_name = item.split('/') // 下载文件, 并存成ArrayBuffer对象
          const file_name = arr_name[arr_name.length - 1] // 获取文件名
          zip.file(file_name, data, {binary: true}) // 逐个添加文件
          cache[file_name] = data
        })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({type: 'blob'}).then(content => {
          // 生成二进制流
          FileSaver.saveAs(content, '文件下载.zip') // 利用file-saver保存文件  自定义文件名
          item.show = false
        })
      })


    },


    getFile(url) {
      return new Promise((resolve, reject) => {
        let obj = {
          method: 'get',
          url,
          // responseType: 'blob'
          responseType: 'arraybuffer'
        }
        axios(obj)
            .then(data => {
              resolve(data.data)
            })
            .catch(error => {
              reject(error.toString())
            }).finally(() => {
          this.checkedCities = []
          this.checkAll = false //头部复选框
          this.isIndeterminate = false //全选控制
        })
      })

    },


    //购物车
    async add(item) {
      try {
        const { message} = await getProductsAddCart(
            {
              designItemId:item.id
            }
        )
        this.$message({
          message,
          type: 'success'
        });

      } catch (e) {
        this.$message.error(e);
      }

    },

    //弹窗开启时
    open() {

      if (!!this.shopId) {
        //购物车
        this.getCartList()
      } else {
        //下载
        this.downloadPicturesList()
      }

    },

    //获取购物车数据
    async getCartList() {
      this.loading = true
      try {
        const { data } = await getCartList(
            {
              design_id: this.shopId
            }
        )
        console.log(data)
        this.shopList = data
      } catch (e) {
        this.$message.error(e)
      }
      this.loading = false
    },

    async downloadPicturesList() {
      this.loading = true
      try {
        const data = await downloadPicturesList({
          designId: this.downloadId
        })
        console.log(data)
        this.downloadList = data?.data.map(val => {
          val.show = false
          return val
        })


      } catch (e) {
        this.$message.error(e)
      }
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.top {
  font-size: 14px;

  .search {
    width: 200px;
    height: 27px;
    margin-left: 10px;
    margin-right: 20px;
  }

  .search-btn {
    background: #FA4A14;
    color: #fff;
    margin-left: 30px;
  }
}

.box {

  //margin-top:15px ;
  .scroll_box {
    height: 420px;
    overflow: auto;
  }

  .add-top {
    display: flex;
    justify-content: space-around;
    font-size: 15px;
    color: #333;
    height: 50px;
    line-height: 50px;
    background: #F7F7F7;

    .title-img {
      width: 100px;
      text-align: center;
    }

    .title-color {
      width: 120px;
      //text-align: center;
    }

    .title-id {
      width: 100px;
      // text-align: center;
    }

  }

  .add-body {
    height: 120px;
    display: flex;
    justify-content: space-around;
    line-height: 120px;
    color: #333;
    border-bottom: 1px solid #E9E9E9;

    .add-size {
      width: 120px;
    }

    .add-img {
      margin-top: 10px;
    }

    .add-operation {
      font-size: 25px;
      color: #FA4A14;
      cursor: pointer;
    }
  }
}


/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #E8E8E8;
}

::-webkit-scrollbar {
  width: 8px; /*对垂直流动条有效*/
  height: 10px; /*对水平流动条有效*/
  // background-color:red
}

.el-icon-loading {
  font-size: 20px;
  color: #FA4A14;
  cursor: no-drop;
}
</style>
