<template>
  <div>
      <sort-list @comeBack="comeBack"/>
  </div>
</template>

<script>
import SortList from './components/SortList.vue'
export default {
  name:'mySortTo',
  components: { SortList },
    data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    comeBack(){
      this.$emit('comeBack')
    }
  }
}
</script>

<style scoped lang="less">

</style>
