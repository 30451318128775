<template>
  <div class="gallery-foot">
    <el-pagination
        background
        layout="prev, pager, next,total"
        :total="meta.total"
        :page-size="parseInt(meta.per_page)"
        prev-text='上一页'
        next-text='下一页'
        @current-change="handleCurrentChange"
        :current-page="parseInt(meta.current_page)"
        :hide-on-single-page="true"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props:{
    meta:{
      type:Object,
      default:[]
    }
  
  },
  data () {
    return {
      
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleCurrentChange(currentPage){
       this.meta.current_page = currentPage;
       this.$emit('currentPage',currentPage)
    }
  }
}
</script>

<style scoped lang="less">
/deep/.btn-prev ,
/deep/.btn-next{
  padding: 0 10px !important;
}
/deep/.el-pagination__total {
  font-size: 14px !important;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}
/deep/.el-pagination  {
  margin: 20px 0;
  text-align: center;
}
</style>
