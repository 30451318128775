<template>
  <div
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="#fff"
  >
    <div class="text_box">
      <div class="basicInformation">基本信息</div>
      <div class="text" @click="goBack"><i class="el-icon-back"></i>返回</div>
    </div>
    <basicInformation
        v-if="JSON.stringify(finishedProductDetails) !== '{}'"
        :finishedProductDetails="finishedProductDetails"
        :info="info"
        :designColors="designColors"
        @setRatio="setRatio"
    />
    <sizeTable
        v-if="JSON.stringify(finishedProductDetails) !== '{}'"
        :designColors="designColors"
    />
    <div class="btn_box">
      <el-button class="btn_style" @click="determine" type="primary">保存</el-button>
      <el-button class="btn_style" @click="goBack">返回</el-button>
    </div>
  </div>


</template>

<script>
import basicInformation from "./components/basicInformation";
import sizeTable from "./components/sizeTable";
import {getFinishedProductDetails, editFinishedProduct} from "@/api/design";

export default {
  name: "index",
  props: {
    designId: {
      type: String | Number
    }
  },
  components: {
    basicInformation,
    sizeTable
  },
  data: () => ({
    loading: false,
    finishedProductDetails: {},
    info: {
      name: '',
      describe: '',
      radio: '',
      designColorId: '',
      tags:'',
      suggestedRetailPrice:'',
      marketPrice:''
    },
    designColors: []
  }),
  computed: {},
  watch: {},
  created() {
    this.getFinishedProductDetails()
  },
  mounted() {

  },
  methods: {
    async getFinishedProductDetails() {
      this.loading = true
      try {
        const {data} = await getFinishedProductDetails({
          designId: this.designId
        })
        this.finishedProductDetails = data
        this.designColors = data.design_colors.map(e => {  //颜色数组
          e.design_item.map(v => {
            v.costPrice = Number(v.size.price) + Number(data?.product.price)
            // v.profit = 0.00
            // v.profitMargin = 0.00
            this.$set(v, 'profitMargin', '0.00')
            this.$set(v, 'profit', '0.00')
            return v
          })
          return e
        })
        this.info.name = data?.name  //名称
        this.info.radio = this.designColors[0].img[0].original
        this.info.describe = data?.describe  //描述
        this.info.tags = data?.tags  //标签
        this.info.suggestedRetailPrice = data?.price //建议零售价
        this.info.marketPrice = data?.msrp //市场价
        console.log(data)

      } catch (e) {
        this.$message.error(e)
      }
      this.loading = false
    },
    async determine() {
      if(!this.info.name) {
        this.$message.error('请输入模板名称')
        return false
      }
      let priceList = []
      this.designColors.forEach(e => {
        e.design_item.forEach(v => {
          priceList.push({
            itemId: v.id,
            sellingPrice:v.price,
            sellingMsrp:v.msrp
          })
        })
      })
      try {
        const {message} = await editFinishedProduct({
          designId: this.designId,
          cover: [{
            designColorId: this.info.designColorId,
            img: this.info.radio
          }],
          name: this.info.name,
          describe: this.info.describe,
          tags:this.info.tags,
          price: priceList,
          sellingPrice:this.info.suggestedRetailPrice,
          sellingMsrp:this.info.marketPrice
        })
        this.$message.success(message)
        this.goBack()
      } catch (e) {
        this.$message.error(e)
      }
    },
    setRatio(val) {
      this.info.radio = val
    },
    goBack() {
      this.$emit('goBack')
    }
  }
}
</script>

<style scoped lang='less'>
.text_box {
  display:flex;
  justify-content: space-between;
}
.btn_box {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 30px;

  .btn_style {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}
.basicInformation{
  font-size: 25px;
  font-weight: 600;
  line-height: 50px;
  color: rgba(0, 0, 0, 0.85);
}
.text {
  line-height: 50px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
</style>