import request from "@/utils/request";
const prefix = '/products'
// 全部产品列表
// export const getProductsList = (
//     {
//        priceName,
//        modelCategoryId,
//        perPage,
//         shippingRange,
//        page
//     },
//     callback
// ) => {
//     const data = {
//        priceName,
//        modelCategoryId,
//        perPage,
//         shippingRange,
//        page
//     }
//     return request(`${prefix}/productList`, {params: data, method: 'get'}, callback)
// }
export const getProductsDeta = (
    {
        productId
    },
    callback
) => {
    const data = {
        productId
    }
    return request(`${prefix}/productDetails`, {params:data, method: 'get'}, callback)
}
// 添加购物车
// export const getProductsAddCart = (
//     {
//         designId,
//         colorId,
//         sizeId,
//         quantity
//     },
//     callback
// ) => {
//     const data = {
//         designId,
//         colorId,
//         sizeId,
//         quantity
//     }
//     return request(`${prefix}/addCart`, {data, method: 'post'}, callback)
// }
export const getProductsAddCart = (
    {
        designItemId,
    },
    callback
) => {
    const data = {
        designItemId,
        quantity:1
    }
    return request('/cart/add', {data, method: 'post'}, callback)
}
// 获取模型分类列表
export const getModel = (
    callback
) => {

    return request(`${prefix}/modelCategoryList`, {params:{}, method: 'get'}, callback)
}
// 获取某个模型分类列表
export const getModelCategory = (
    {
        categoryId
    },
    callback
) => {
    const data = {
        categoryId
    }
    return request(`${prefix}/modelCategoryInfiniteList`, {params:data, method: 'get'}, callback)
}
// 收藏列表
export const getFavoriteList = (
    {
        keyword,
        classifyId,
        state,
        is_common,
        perPage,
        page
    },
    callback
) => {
    const data = {
        keyword,
        classifyId,
        state,
        is_common,
        perPage,
        page
    }
    return request(`${prefix}/productFavoriteList`, {params:data, method: 'get'}, callback)
}
// 添加收藏
export const postProductsAddFavorite = (
    {
        productId,
    },
    callback
) => {
    const data = {
        productId,
    }
    return request(`${prefix}/addProductFavorite`, {data, method: 'post'}, callback)
}
// 取消收藏
export const postDeleteFavorite = (
    {
        favoriteId,
    },
    callback
) => {
    let data = {
       favoriteId,
    }
    
    return request(`${prefix}/deleteProductFavorite`, {data, method: 'delete'}, callback)
}
// 设置常用
export const postFavoriteCommon = (
    {
        favoriteId,
        is_common
    },
    callback
) => {
    const data = {
       favoriteId,
       is_common
    }
    return request(`${prefix}/productFavoriteCommon`, {params:data, method: 'put'}, callback)
}
// 编辑导出名
export const postExportAlias = (
    {
        favoriteId,
        export_alias
    },
    callback
) => {
    const data = {
       favoriteId,
       export_alias
    }
    return request(`${prefix}/reviseExportAlias`, {params:data, method: 'put'}, callback)
}
// 配置收藏
export const putFavoriteConfig = (
    {
        favoriteId,
        config
    },
    callback
) => {
    const data = {
       favoriteId,
       config
    }
    return request(`${prefix}/productFavoriteConfig`, {params:data, method: 'put'}, callback)
}
// 封面排序
export const productFavoriteImgSort = (
    {
        favoriteId,
        color_id,
        config
    },
    callback
) => {
    const data = {
       favoriteId,
       color_id,
       config
    }
    return request(`${prefix}/productFavoriteImgSort`, {params:data, method: 'put'}, callback)
}