<template>
  <div class="sort-compile">
    <el-dialog
        title="编辑"
        :visible.sync="show"
        width="30%"
        :before-close="close"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="60px" class="demo-ruleForm" size="small">
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name" :placeholder="Rulse.name"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="describe">
          <el-input type="textarea" v-model="ruleForm.describe" :placeholder="Rulse.brief"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button type="primary" @click="compileSure">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean
    },
    Rulse: {
      type: Object
    }
  },
  data() {
    return {
      ruleForm: {
        name: '',
        describe: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        describe: [
          {required: true, message: '请输入描述', trigger: 'blur'}
        ]
      },
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    close() {
      this.$emit('comshow', false)
    },
    compileSure() {
      console.log(this.ruleForm.name);
      console.log(this.ruleForm.describe);
      this.$emit('compileRule', this.ruleForm)
      this.close()
    }
  }
}
</script>

<style scoped lang="less">

</style>
