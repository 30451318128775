<template>
  <div>
    <el-dialog
        :before-close="handleClose"
        :visible.sync="exportPop"
        title="产品汇出"
        width="880px">
     <div class="PopBody">
       <div class="top">
         <el-alert
             title="警告提示的文案"
             type="warning"
             :closable="false"
             show-icon>
           <div slot="title">
             <div>1:如发布到同个店铺，可选择更新 </div>
             <div>2:系统将智能关联颜色、尺寸、并使用系统颜色命名。您可以在汇出后，在“汇出记录“下载对应的Excel按需进行调整</div>
           </div>
         </el-alert>
       </div>
       <el-row class="bottom">
         <el-col :span="12" class="left">
           <el-form  ref="form" :rules="rules" :model="form" label-width="80px" >
             <el-form-item label="发布店铺" prop="name">
               <el-select v-model="form.name" placeholder="请选择" class="popCascade">
                 <el-option
                     v-for="item in optionsShop"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
                 </el-option>
               </el-select>
             </el-form-item>
<!--           <el-form-item label="是否更新">-->
<!--             <el-radio-group v-model="form.resource">-->
<!--               <el-radio label="不更新" value="1"></el-radio>-->
<!--               <el-radio label="更新" value="2"></el-radio>-->
<!--             </el-radio-group>-->
<!--           </el-form-item>-->
             <el-form-item label="产品模板" prop="product">
               <el-select v-model="form.product" placeholder="请选择" class="popCascade" @change="userNameId">
                 <el-option
                     v-for="item in optionsProduct"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
                 </el-option>
               </el-select>
             </el-form-item>
<!--             <el-form-item label="汇出格式">-->
<!--               <el-radio-group v-model="form.format">-->
<!--                 <el-radio label="Excel" value="0"></el-radio>-->
<!--                 <el-radio label="简版Excel" value="1"></el-radio>-->
<!--                 <el-radio label="Txt" value="2"></el-radio>-->
<!--               </el-radio-group>-->
<!--             </el-form-item>-->
             <el-form-item label="定价模板" prop="region">
               <el-select v-model="form.region" placeholder="请选择活动区域" class="popCascade" @change="pricingTemplateChange">
                 <el-option
                     v-for="item in optionsFixedPrice"
                     :key="item.id"
                     :label="item.name"
                     :value="item.id">
                 </el-option>
               </el-select>
             </el-form-item>
           </el-form>
         </el-col>
         <el-col :span="12" class="right">
           <div class="rightTitle">
             <span class="title">参考预览</span>
<!--             <span class="active">刷新</span>-->
           </div>
           <div class="variants">
             <el-row class="item">
               <el-col :span="11">变体、价格信息预览</el-col>
  <!--             <el-col :span="8" class="active">指定变体汇出</el-col>-->
  <!--             <el-col :span="5" class="active">快速定价</el-col>-->
             </el-row>
<!--             <el-row class="item" v-for="(item, index) in productInformationBrowse" :key="index">-->
<!--               <el-col :span="11" class="default line">{{item.product_name}}</el-col>-->
<!--               <el-col :span="8" class="default">{{item.numberOfVariants}}个变体</el-col>-->
<!--               <el-col :span="5" class="default">20-30美元</el-col>-->
<!--             </el-row>-->
             <div style="display: flex;">
               <div style="width: 280px;">
                 <el-row class="content" v-for="(item,index) in productInformationBrowse" :key="index">
                   <el-col :span="18">{{item.product_name}}</el-col>
                   <el-col :span="6">{{item.numberOfVariants}}个变体</el-col>
                 </el-row>
               </div>
               <div style="flex: 1;display: flex;justify-content:flex-end;" v-for="(item,index) in productList" :key='index' >{{item.lowestPrice}} - {{item.highestPrice}}</div>
             </div>
           </div>
           <div class="templateCategory">
             <span class="category">产品模版类目：</span>
             <span class="default">{{productTemplate.feed_product_type || '请选择产品模板'}}</span>
           </div>
           <div class="productSKU">
             <el-row class="productTitle">
               <el-col :span="22" class="flex">
                 <div
                     class="product"
                     v-for="(item,index) in list"
                     :key="index"
                     @click="cutover(index,item)"
                     :class="{'active':active === index}"
                 >
                   {{item}}
                 </div>
               </el-col>
               <el-col :span="2">
<!--                 <span class="active">修改</span>-->
               </el-col>
             </el-row>
             <div class="content" v-show="value === '产品标题'">{{ productTemplate.item_name || '请选择产品模板' }}</div>
             <div class="content" v-show="value === 'SKU'">{{ productTemplate.item_sku || '请选择产品模板' }}</div>
             <div class="content" v-show="value === '产品描述'">{{productTemplate.product_description || '该产品未填写描述'}}</div>
           </div>
<!--           <div class="rightFoot">-->
<!--             <span>条形码：</span>-->
<!--             <span class="default">UPC</span>-->
<!--             <span>数量不足</span>-->
<!--           </div>-->
         </el-col>
       </el-row>
     </div>
      <span slot="footer" class="dialog-footer">
         <el-button @click="handleClose">取 消</el-button>
         <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { shopSelect, templatesScreen, exportProducts, containsVariantInformation } from '@/api/productTemplate'
import { pricingTemplatesScreen } from '@/api/pricingTemplate'
import { getPricingInformation} from "@/api/exportRecords";
export default {
  props:{
    exportPop:{
      type:Boolean
    },
    designId:{
    }
  },
  data() {
    return {
      form:{
        name:null,
        resource:1,
        product:null,
        region:null,
        format:0,  //格式
      },
      rules:{
        name:[
          { required: true, message: '请选择发布店铺', trigger: 'blur' },
        ],
        product:[
          { required: true, message: '请选择产品模板', trigger: 'blur' },
        ],
        region:[
          { required: true, message: '请选择定价模板', trigger: 'blur' },
        ],
      },
      options:[
        {
          value:'1',
          label:'开始',
          children:[
            {
              value:'11',
              label:'结束'
            }
          ]
        }
      ],
      optionsShop:[],       //发布店铺
      optionsProduct:[],    //产品模板
      optionsFixedPrice:[],    //定价模板
      list:['SKU','产品标题','产品描述'],
      active:0,
      value:'SKU',
      productTemplate:{},   //产品模板信息
      productInformationBrowse:[],   //产品信息浏览
      productList:[]
    }
  },
  created() {
    this.shopSelect()
    this.templatePaginate()
    this.pricingTemplatesScreen()
  },
  mounted() {

  },
  methods: {
    async pricingTemplateChange(e){
      try {
        const {data} = await getPricingInformation({
          store_id:this.form.name,
          pricing_template_id:e,
          user_template_id:this.form.product,
          design_id:this.designId.join(',')
        })
        this.productList = data?.product
        console.log(this.productList)
      }catch (e) {
        console.log(e)
        this.$message.error(e?.msg)
      }
    },
    handleClose(){
      this.form.region = null
      this.form.resource = 1
      this.form.name = null
      this.form.product = null
      this.form.format = 0
      this.productTemplate={}   //产品模板信息
      this.productInformationBrowse=[]  //产品信息浏览
      this.productList = []
      this.$emit('close',false)
    },
    cutover(index,item){
      this.active = index
      this.value = item
    },
  //  获取店铺列表
   async shopSelect(){
      try {
        const { data, msg } = await shopSelect()

        this.optionsShop = data
      } catch (e) {
        this.$message.error(e)
        console.log(e)
      }
    },
    //产品模板
    async templatePaginate(){
      try {
        const { data, msg } = await templatesScreen()
        this.optionsProduct = data

      } catch (e) {
        this.$message.error(e)
        console.log(e)
      }
    },
    //定价模板
    async pricingTemplatesScreen(){
      try {
        const { data, msg } = await pricingTemplatesScreen()
        this.optionsFixedPrice = data
      } catch (e){
        this.$message.error(e)
        console.log(e)
      }
    },
    //用户id改变
    userNameId(){

      this.containsVariantInformation()
    },
  //  汇出产品——变体信息
   async containsVariantInformation(){
      try {
        const { data, msg } = await containsVariantInformation
        (
            {
              design_id:this.designId,
              user_template_id:this.form.product
            }
        )

        this.productTemplate = data.parameter
        this.productInformationBrowse = data.product
      } catch (e) {
        this.$message.error(e)
        console.log(e)
      }
    },
  //  确认提交
   determine(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.exportProducts()
          this.handleClose()
          this.form = {}
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //exportProducts
    async exportProducts(){
      try {
        const { data, msg } = await exportProducts(
            {
              design_id:this.designId,
              user_template_id:this.form.product,
              store_id:this.form.name,
              pricing_template_id:this.form.region
            }
        )
        this.$message({
          message: data,
          type: 'success'
        });
        this.$emit('update')
      } catch (e) {
        this.$message.error(e);
      }
    },
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog{
  //height: 652px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}
/deep/.el-dialog__header{
  padding:0 0 20px;
  font-size: 18px;
  color: #333333;
  border-bottom: 1px solid #D8D8D8;
}
/deep/.el-dialog__body{
  padding: 5px 0;
}
/deep/.el-form-item{
  margin-bottom: 10px;
}
.el-alert--warning.is-light{
  background-color:rgba(250, 74, 20, 0.1);
  color:#666666;
  font-size: 14px;
  padding: 10px 44px;
}
.bottom{
  margin-top: 20px;
}
.popCascade{
  width: 332px;
  height: 40px;
}
.flex {
  display: flex;
  padding:20px 0;
}
.product {
  padding: 0 10px;
  border-right: 1px solid #333333;
  cursor:pointer;
}
.line{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.right{
  padding:0 0 0 20px ;
  .active{
    font-size: 14px;
    font-weight: 400;
    color: #FA4A14;
    cursor: pointer;
  }
  .default{
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    margin-bottom: 6px;
  }
  .rightTitle{
    padding: 10px 0;
    border-bottom: 1px solid #E9E9E9;
    .title{
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
      margin-right: 14px;
    }
  }
  .variants{
    padding: 20px 0;
    border-bottom: 1px solid #E9E9E9;
    .item{
      margin-bottom: 10px;
    }
  }
  .templateCategory{
    padding: 20px 0;
    border-bottom: 1px solid #E9E9E9;
    .category{
      color: #333;
      font-size: 14px;
    }
  }
  .productSKU{
    padding: 20px 0;
    border-bottom: 1px solid #E9E9E9;
    .bar{
      margin: 0 10px;
    }
    .productTitle{
      margin-bottom: 15px;
    }
  }
  .rightFoot{
    padding: 20px 0;
    border-bottom: 1px solid #E9E9E9;
  }
}
</style>