<template>
  <div>
    <div class="app" v-if="!mySortToShow && !productEditShow">
      <!-- 批量归类 -->
      <batch-classify :BatchShowClass="BatchShowClass" @close="Close" @sort="sort"/>
      <design-top @themeId="ThemeId" @time="time" @mySortToHide="mySortToHide" @setProductType="setProductType"/>
      <!-- <manage-top v-else @found="Found" :tsum="themeSum"/> -->
      <products-list :ProductList="productList" @edit="edit"
                     ref="productsRef"
                     @removeId="removeId"
                     @namecode="NameCode"
                     @batch="Batch"
                     @All="All"
                     @single="single"
                     @subAccountChange="subAccountChange"
                     v-loading="loading"
                     element-loading-text="拼命加载中"
                     element-loading-spinner="el-icon-loading"/>
      <!-- <sort-list v-else :foundshow="foundShow" @fshow="Found" @sum="Sum" @found="Found"/> -->
      <!-- 编辑弹窗 -->
<!--      <compile :EditShow="editShow"-->
<!--               @uplode="editshow"-->
<!--               :DesignMatrix="designMatrix"-->
<!--               :DesignVariant="designVariant"-->
<!--               :designMatrixImg="designMatrixImg"-->
<!--               :designMatrixImgList="designMatrixImgList"-->
<!--               :designId="designId"-->
<!--               @closeEdit="closeEdit"-->
<!--               :compileLoading="compileLoading"-->
<!--      />-->
      <gallery-foot :meta="meta" @currentPage="currentPage"/>
    </div>
    <div v-else-if="mySortToShow">
      <my-sort-to @comeBack="comeBack"/>
    </div>
    <div v-else-if="productEditShow">
      <product-edit
          :designId="designId"
          @goBack="goBack"
      />
    </div>
  </div>
</template>

<script>
import mySortTo from "@/views/design/mySortTo/index";
import productEdit from '../productEdit/index'
import DesignTop from '../myProducts/components/DesignTop'
import ProductsList from './components/ProductsList.vue'
import Compile from './components/compile';
import {getMyDesign, getDesignInfo, deleteDesign, updateDesignTheme} from '@/api/design'
import {mapMutations, mapState} from 'vuex'
import {
  getBeginWeek, //本周的开始时间
  getEndWeek,  // 本周的结束时间
  getBeginToday, // 今天的开始时间
  getCurrentDate, //当前时间
  getBeginYesterday, //昨天的开始时间
  getEndYesterday, // 昨天的结束时间
  getBeginLastWeek, //上周的开始时间
  getEndLastWeek, // 上周的结束时间
  getBeginMonth, // 本月的第一天
  getEndMonth, //本月的最后一天，
  getBeginLastMonth, //上个月的一天
  getEndLastMonth, //上个月最后一天
  year
} from '@/utils/timejs.js';
import GalleryFoot from '../../mapDepot/allGalleries/components/GalleryFoot.vue'
import BatchClassify from './components/BatchClassify.vue'

export default {
  name: "Index",
  data() {
    return {
      productEditShow:false,
      productTypeId:null,


      mySortToShow: false,
      checkbox: false,
      shopcart_show: false,
      productList: [],
      productDetails: {},
      loading: true,
      sort_show: true,
      editShow: false,
      designId: null,
      designMatrix: {},
      designVariant: [],
      // 类别id
      theme: 0,
      nameCode: null,   //编号和名称
      removeid: null,    //删除id
      foundShow: false,  //创建弹窗
      themeSum: 0,   //类别总数
      startTime: null, //今天开始时间
      endTime: null,//现在的时间
      designMatrixImg: '',  //母体图片
      meta: {},
      BatchShowClass: false,   //批量归类操作弹窗
      all: [],     //全选值
      sortId: null,  //类别id
      page: 1,
      userCode: null,   //子账号code
      designMatrixImgList: [],
      batchId: null,
      compileLoading: false
    }
  },
  created() {
    this.getMyDesign()
  },
  mounted() {

  },
  components: {
    DesignTop,
    ProductsList,
    Compile,
    GalleryFoot,
    BatchClassify,
    mySortTo,
    productEdit
  },
  computed: {
    ...mapState(['variantInformation'])
  },
  methods: {

    goBack(){
      this.productEditShow = false
      this.getMyDesign()
    },

    comeBack() {
      this.mySortToShow = false
    },
    ...mapMutations([
      'moreChangeable'
    ]),

    mySortToHide() {
      this.mySortToShow = true
    },
    //编辑弹窗关闭
    closeEdit() {
      this.editShow = false
    },

    //模板类型
    setProductType(id) {
      this.productTypeId = id
      this.page = 1
      this.getMyDesign()
    },


    // 获取产品列表
    async getMyDesign() {
      this.loading = true
      try {
        const {data} = await getMyDesign(
            {
              perPage: 10,
              themeId: this.theme,
              // nameOrCode:this.nameCode,
              // startTime:this.startTime,
              // endTime:this.endTime,
              type:this.productTypeId,
              keyword: this.nameCode,
              page: this.page,
              synthesisTimeBetween: this.startTime && this.endTime ? [this.startTime, this.endTime] : undefined
              // user_code:this.userCode
            }
        )
        this.productList = data.data
        this.meta = data.meta
      } catch (e) {
        this.$message.error(e)
      }
      this.loading = false
    },
    //获取到的子账号
    subAccountChange(val) {

      this.userCode = val
      this.page = 1
      this.getMyDesign()
    },

    currentPage(val) {
      this.page = val
      this.getMyDesign()
    },
    //编辑
    edit( id) {
      // this.compileLoading = true
      // this.editShow = val
      // this.designId = id
      // this.getDesignInfo()
      this.designId = id
      this.productEditShow = true
    },
    // 批量操作
    Batch(id) {
      this.BatchShowClass = true
      this.batchId = id
    },
    // 全选值
    All(val) {
      this.all = val
    },
    single(val) {
      this.all = val
    },
    //归类
    async sort(val) {
      this.sortId = val
      let id = []

      !!this.batchId ? (id.push(this.batchId)) : (id = this.all)

      try {
        const {message} = await updateDesignTheme(
            {
              designId: id,
              themeId: this.sortId
            }
        )
        await this.getMyDesign()
        this.$message({
          message,
          type: 'success'
        });

      } catch (e) {
        this.$message.error(e)
      }

      this.$refs.productsRef.checkColse()
    },
    // 关闭批量归类
    Close(val) {
      this.BatchShowClass = val
    },
    // 控制编辑弹窗
    editshow(val) {
      this.editShow = val
      this.getMyDesign()
    },
    // 编辑信息
    // async getDesignInfo() {
    //
    //   try {
    //     const {data} = await getDesignInfo(
    //         {
    //           design_id: this.designId
    //         }
    //     )
    //     this.designMatrix = data.design_matrix
    //     this.designVariant = data.design_variant
    //     this.designMatrixImg = this.designMatrix.img[0].img400
    //     this.designMatrixImgList = []
    //     for (let i = 0; i < this.designMatrix.img.length; i++) {
    //       this.designMatrixImgList.push(this.designMatrix.img[i].img400)
    //     }
    //     this.moreChangeable(this.designVariant[0])
    //   } catch (e) {
    //     this.$message.error(e)
    //   }
    //   this.compileLoading = false
    // },
    //  类别id
    ThemeId(val) {
      this.theme = val
      this.nameCode = null
      this.page = 1
      this.getMyDesign()
    },
    //  编号和名称
    NameCode(name) {
      this.nameCode = name;
      this.page = 1;
      this.getMyDesign()
    },
    // 删除
    async removeId(id) {
      this.$confirm('此操作将永久删除该产品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          const {message} = await deleteDesign(
              {
                designId: id
              }
          )
          this.$message.success(message);
          await this.getMyDesign()
        } catch (e) {
          this.$message.error(e)
        }
      }).catch(() => {
        return false
      });
    },
    // 创建弹窗
    Found(val) {
      this.foundShow = val
    },
    // 总数
    Sum(val) {
      this.themeSum = val
    },
    // 更改时间
    time(event) {
      this.page = 1
      if (event === '所有') {
        this.startTime = null
        this.endTime = null
      } else if (event === '今天') {
        this.startTime = Date.parse(getBeginToday()) / 1000
        this.endTime = Date.parse(getCurrentDate()) / 1000

      } else if (event === '昨天') {
        this.startTime = getBeginYesterday() / 1000
        this.endTime = parseInt(getEndYesterday() / 1000)

      } else if (event === '本周') {
        this.startTime = getBeginWeek() / 1000
        this.endTime = parseInt(getEndWeek() / 1000)
      } else if (event === '本月') {
        this.startTime = getBeginMonth() / 1000
        this.endTime = parseInt(getEndMonth() / 1000)
      } else if (event === '上月') {
        this.startTime = getBeginLastMonth() / 1000
        this.endTime = parseInt(getEndLastMonth() / 1000)
      } else if (event === '今年') {

      }
      this.nameCode = null
      this.getMyDesign()
    },
  }
}
</script>

<style scoped lang="sass">

</style>

