<template>
  <div>
    <sort-pop :show="sortComShow" :Rulse="rulse" @comshow="Comshow" @compileRule="CompileRule"/>
    <found-pop :Fshow="foundShow" @comShow="FoundCom" @ruleform="RuleForm"/>
    <div class="manage">
      <div class="left">
        <!-- <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate"  @change="handleCheckAllChange"></el-checkbox>
          <el-select v-model="batchValue"  class="batch-operation" size="mini" placeholder="批量操作" @change="del">
            <el-option
              v-for="(item1,index1) in batchOptions"
              :key="index1"
              :label="item1.label"
              :value="item1.value"
              >
            </el-option>
          </el-select> -->
        <div style="margin-bottom: 10px">
          <button class="btnRemove" @click="comeBack">返回</button>
        </div>
        <button class="btnRemove" @click="del">批量删除</button>
      </div>
      <div class="right">
        <div>共<span class="text">{{ sum }}</span>个</div>
        <el-button size="mini" class="btn" @click="setSort">创建类别</el-button>
      </div>
    </div>
    <!-- <div class="goods">
      <div class="box" v-for="(item, index) in themeList" :key="index">
        产品图片
        <div class="top">
          <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
            <el-checkbox :label="item.id"></el-checkbox>
          </el-checkbox-group>
               <el-image
              style="width: 100%; height: 100%"
              :src="item.photo"
              fit='none'
              ></el-image>
        </div>
        产品介绍
        <div class="bottom">
            <div class="title">{{item.name}}</div>
            <div class="foot">
                <div class="foot-text">一天前</div>
                   <el-dropdown>
                   <el-button  size="mini" class="s-btn"><i class="el-icon-more"></i></el-button>
                     <el-dropdown-menu slot="dropdown">
                         <el-dropdown-item @click.native="sortCom(item,item.id)">编辑</el-dropdown-item>
                         <el-dropdown-item @click.native="remove(item.id)">删除</el-dropdown-item>
                     </el-dropdown-menu>
                   </el-dropdown>
            </div>
          </div>
      </div>
    </div> -->
    <el-row :gutter="10" class="title">
      <el-col :span="1" style="padding-left: 10px;">
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange"></el-checkbox>
      </el-col>
      <el-col :span="11">
        名称
      </el-col>
      <el-col :span="10">
        描述
      </el-col>
      <!-- <el-col :span="8">
        产品数量
      </el-col> -->
      <el-col :span="2">
        操作
      </el-col>
    </el-row>
    <el-row :gutter="10" v-for="(item, index) in themeList" :key="index" class="el_row_text">
      <el-col :span="1" style="padding-left: 10px;">
        <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
          <el-checkbox :label="item.id"></el-checkbox>
        </el-checkbox-group>
      </el-col>
      <el-col :span="11">
        {{ item.name }}
      </el-col>
      <el-col :span="10">{{ item.brief }}</el-col>
      <!-- <el-col :span="8"></el-col> -->
      <el-col :span="2">
        <span class="edit" @click="sortCom(item,item.id)">编辑</span>
        <span @click="remove(item.id)">删除</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {getThemeList, addTheme, editAlbum, deleteTheme} from '@/api/design'
import SortPop from './SortPop.vue'
import FoundPop from './FoundPop.vue'

export default {
  components: {SortPop, FoundPop},
  data() {
    return {
      // 批量操作值
      batchValue: '',
      // 批量操作
      batchOptions: [
        {
          value: 1,
          label: '删除'
        },
      ],
      visible: false,
      sortComShow: false,
      // 主题列表
      themeList: [],
      FoundShow: false,
      sum: 0,  //总数
      rulse: {},
      themeId: null,
      //显示创建弹窗
      foundShow: false,
      checkAll: false, //头部复选框
      isIndeterminate: false, //全选控制
      checkedCities: [], //选择的商品
    }
  },
  created() {
    this.getThemeList()
  },
  mounted() {

  },
  methods: {
    //返回
    comeBack() {
      this.$emit('comeBack')
    },

    // 全选框
    handleCheckAllChange(val) {

      var arr = []
      this.themeList.forEach(e => {
        arr.push(e.id)
      });
      this.checkedCities = val ? arr : []
      this.isIndeterminate = false

    },
    // 单选
    handleCheckedCitiesChange(val) {
      var arr = []
      this.themeList.forEach(e => {
        arr.push(e.id)
      });
      this.checkAll = val.length === arr.length
      this.isIndeterminate = val.length > 0 && val.length < arr.length

    },
    // 获取主题列表
    async getThemeList() {
      try {
        const {data} = await getThemeList()
        this.themeList = data

        this.sum = data.length
        this.$emit('sum', this.sum)
      } catch (e) {
        this.$message.error(e)
      }

    },
    //   编辑
    sortCom(item, id) {
      this.sortComShow = true
      this.rulse = item
      this.themeId = id

    },
    Comshow(val) {
      this.sortComShow = val
    },
    // 编辑主题
    async CompileRule(val) {
      try {
        const {message} = await editAlbum(
            {
              themeId: this.themeId,
              name: val.name,
              brief: val.describe
            }
        )

        await this.getThemeList()
        this.$message({
          message,
          type: 'success'
        });
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 批量删除
    async del() {
      if(this.checkedCities.length > 0 ) {
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {

          await deleteTheme(
              {
                themeId: this.checkedCities
              }
          )
          this.batchValue = ''
          this.checkAll = false
          this.isIndeterminate = false
          this.checkedCities = []
          await this.getThemeList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch((e) => {
          if (e === 'cancel') {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          } else {
            this.$message({
              type: 'error',
              message: e
            });
          }
        });
      } else {
        this.$message.error('请勾选再删除')
      }



    },
    // 删除主题
    async remove(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteTheme(
            {
              themeId: id
            }
        )
        await this.getThemeList()
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch((e) => {
        if (e === 'cancel') {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        } else {
          this.$message({
            type: 'error',
            message: e
          });
        }

      });

    },
    // 创建弹窗
    setSort() {
      this.foundShow = true
      //   this.$emit('found',this.foundShow)
    },
    // 关闭弹窗
    FoundCom(val) {
      this.foundShow = val
    },
    // 添加主题
    async RuleForm(val) {
      try {
        const {message} = await addTheme(
            {
              name: val.name,
              brief: val.describe
            }
        )
        await this.getThemeList()
        this.$message({
          message,
          type: 'success'
        });
      } catch (e) {
        console.log(e);
        this.$message.error(e)
      }
    },
  }
}
</script>

<style scoped lang="less">
.manage {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.batch-operation {
  width: 100px;
  height: 26px;
  margin-left: 20px;
}

.right {
  text-align: center;
  margin-right: 30px;

  .text {
    color: #FA4A14;
    font-size: 28px;

  }

  .btn {
    width: 94px;
    height: 26px;
    background: #FA4A14;
    color: #fff;
    margin-top: 5px;

  }
}

/deep/ .el-checkbox__label {
  color: rgba(0, 0, 0, 0) !important;
}

.btnRemove {
  width: 80px;
  height: 26px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #FA4A14;
  font-size: 14px;
  font-weight: 400;
  color: #FA4A14;
}

.title {
  background: #F7F7F7;
  line-height: 50px;
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  margin-top: 15px;
}

.el_row_text {

  line-height: 50px;
  background: #FFFFFF;
  margin-top: 1px;
  border-bottom: 1px solid #E9E9E9;
  font-size: 14px;
  font-weight: 400;
  color: #333333;

  &:hover {
    box-shadow: 0px 5px 12px 4px rgba(172, 172, 172, 0.16);
  }

}

/deep/ .el-col {
  border: 1px solid transparent;

  & > span:hover {
    color: #FA4A14;
    cursor: pointer;
  }
}

.edit {
  margin-right: 10px;
}
</style>
