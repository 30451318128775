<template>
  <div>
    <el-dialog
        :title=setTitle
        :visible.sync="modifyShow"
        width="30%"
        :before-close="handleClose">
      <el-form @submit.native.prevent label-width="100px"  :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item :label="title" prop="name">
          <el-input  v-model="ruleForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "modify",
  props: {
    title: {
      type: String,
      default: null
    },
    modifyShow:{
      type:Boolean,
      default:false
    }
  },
  data: () => ({
    ruleForm:{
      name:''
    },
    rules:{
// /^\d+(\.\d+)?$/
      name:[{ required: true, message:'请输入', trigger: 'blur' },]
    }
  }),
  computed: {
    setTitle(){
      return `修改${this.title}`
    },

  },
  watch: {},
  mounted() {
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
      this.$refs.ruleForm.resetFields();
    },
    determine(){
      this.$refs.ruleForm.validate((valid) => {
        if(valid) {
          this.$emit('modifyTheValue',this.title,this.ruleForm.name)
          this.handleClose()
        }
      })
    }
  }
}
</script>

<style scoped lang='less'>

</style>