import request from "@/utils/request";
const prefix = '/publish'

//添加定价模板
export const addPricingTemplate = (
    params,
    callback
) => {

    return request(`${prefix}/addPricingTemplate`, { params,method: 'post'}, callback)
}
//定价产品分页列表
export const pricingTemplatePaginate = (
    params,
    callback
) => {

    return request(`${prefix}/pricingTemplatePaginate`, { params,method: 'get'}, callback)
}
//获取定价模板筛选列表
export const pricingTemplatesScreen = (

    params,
    callback

) => {

    return request(`${prefix}/pricingTemplatesScreen`, { params,method: 'get'}, callback)
}


//获编辑定价模板
export const editPricingTemplate = (
    params,
    callback
) => {

    return request(`${prefix}/editPricingTemplate`, { params,method: 'put'}, callback)
}
// 复制
// copyPricingTemplate
export const copyPricingTemplate = (
    params,
    callback
) => {

    return request(`${prefix}/copyPricingTemplate`, { params,method: 'post'}, callback)
}

// 定价产品分页列表
export const pricingProductsPaginate = (
    params,
    callback
) => {

    return request(`${prefix}/pricingProductsPaginate`, { params,method: 'post'}, callback)
}

//产品定价详情

export const productsPricingPaginate = (
    params,
    callback
) => {
    return request(`${prefix}/productsPricingDetails`, { params,method: 'get'}, callback)
}
// 货币汇率
export const currencyRate = (
    params,
    callback
) => {
    return request(`${prefix}/currencyRate`, { params,method: 'get'}, callback)
}

//保存 saveProductsPricing
export const saveProductsPricing = (
    data,
    callback
) => {
    return request(`${prefix}/saveProductsPricing`, { data,method: 'put'}, callback)
}
// 删除
// deletePricingTemplate
export const deletePricingTemplate = (
    params,
    callback
) => {
    return request(`${prefix}/deletePricingTemplate`, { params,method: 'delete'}, callback)
}

// 产品定价详情

export const productsPricingDetails = (
    params,
    callback
) => {
    return request(`${prefix}/productsPricingDetails`, { params,method: 'get'}, callback)
}
//产品颜色
export const productColor = (
    params,
    callback
) => {
    return request(`/products/productColor`, { params,method: 'get'}, callback)
}
//产品尺寸
export const productSize = (
    params,
    callback
) => {
    return request(`/products/productSize`, { params,method: 'get'}, callback)
}
//物流列表(带价格展示)
export const getLogisticsWithPriceList = (

    callback
) => {
 const  params = {
     country: 'US',
     weight: 100,
     factoryCode:'202108281112471114780'
    }
    return request(`/logistics/getLogisticsWithPriceList`, { params,method: 'get'}, callback)
}

//获取国家站点publish/amazonRegionCountrySelect
export  const amazonRegionCountrySelect = (callback) =>{
    return request(`${prefix}/amazonRegionCountrySelect`, {method: 'get'}, callback)
}