import request from "@/utils/request";
const prefix = '/publish'

// 获取主题
export const exportRecordPaging = (
    params,
    callback
) => {
    return request(`${prefix}/exportRecordPaging`, { params,method: 'get'}, callback)
}
// 下载汇出产品模板
export const downloadExportTemplate = (
    params,
    callback
) => {
    return request(`${prefix}/downloadExportTemplateUrl`, {params, method: 'get'}, callback)
}
//导出-汇出产品
// exportProducts
export const exportProducts = (
    params,
    callback
) => {
    return request(`${prefix}/exportProducts`, {params, method: 'post'}, callback)
}

// 汇出参考预览
export const exportReferencePreview = (
    params,
    callback
) => {
    return request(`${prefix}/exportReferencePreview`, {params, method: 'get'}, callback)
}
//查看
// exportProductsPaging
export const exportProductsPaging = (
    params,
    callback
) => {
    return request(`${prefix}/exportProductsPaging`, {params, method: 'get'}, callback)
}
//变体信息
// containsVariantInformation
export const containsVariantInformation = (
    params,
    callback
) => {
    return request(`${prefix}/containsVariantInformation`, {params, method: 'get'}, callback)
}

//getPricingInformation获取定价信息
export const getPricingInformation = (
    params,
    callback
) => {
    console.log(params)
    return request(`${prefix}/getPricingInformation`, {params, method: 'get'}, callback)
}
