import { render, staticRenderFns } from "./BatchClassify.vue?vue&type=template&id=593d5eb0&scoped=true&"
import script from "./BatchClassify.vue?vue&type=script&lang=js&"
export * from "./BatchClassify.vue?vue&type=script&lang=js&"
import style0 from "./BatchClassify.vue?vue&type=style&index=0&id=593d5eb0&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "593d5eb0",
  null
  
)

export default component.exports