<template>
  <div class="compile">
    <el-dialog
        title="编辑"
        :visible.sync="EditShow"
        width="868px"
        :before-close="handleClose">
      <div class="compile-box"
           v-loading="compileLoading"
           element-loading-text="拼命加载中"
           element-loading-spinner="el-icon-loading"
           element-loading-background="#fff"
      >
        <div class=""></div>
        <div class="left" v-if="!compileLoading">
          <!--      //母体信息-->
          <div class="matrix">
            <div class="matrix-title">母体信息</div>
            <div class="matrix-img">
              <el-image
                  style="width: 100%;height: 100%;"
                  :src="matrixImgUrl || designMatrixImg" :preview-src-list="designMatrixImgList" alt="">
                <div slot="error" class="image-slot" style="width: 100%;height: 100%;">
                  <img :src="url" alt="" style="width: 100%;height: 100%;">
                </div>
              </el-image>
            </div>
            <div class="small-box">
              <i class="el-icon-arrow-left " @click="previous" :class="{'disabledShow':disabledShow}"></i>
              <div class="small-img-box">
                <div class="small-img" v-for="(item, index) in DesignMatrix.img"
                     :style="{'transform': `translateX(${translatePx}px)`}" :class="{'show':matrixImgIndex === index}"
                     :key="index" @click="matrixImg(item.img400,index)">
                  <el-image :src="item.img400" alt="" style="width: 100%;height: 100%;">
                    <div slot="error" class="image-slot" style="width: 100%;height: 100%;">
                      <img :src="url" alt="" style="width: 100%;height: 100%;">
                    </div>
                  </el-image>
                </div>
              </div>
              <i class="el-icon-arrow-right" @click="next" :class="{'disabledShow':disabledShowNext}"></i>
            </div>
          </div>


          <!--      变体信息-->
          <div class="variant" v-if="variantInfo">
            <div class="variant-title">变体信息</div>
            <div class="variant-img">
              <el-image :src="variantImgUrl" @click="bigPicture" :preview-src-list="variantList" alt=""
                        style="width: 100%;height: 100%;">
                <div slot="error" class="image-slot" style="width: 100%;height: 100%;">
                  <img :src="url" alt="" style="width: 100%;height: 100%;">
                </div>
              </el-image>
            </div>
            <!--        <div class="variant-box" >-->
            <!--          <i class="el-icon-arrow-left"></i>-->
            <!--          <div class="small-variant" v-for="(item2,index2) in variantInfo.imgThumb" :key="index2" @click="variantImg(item2.img)">-->
            <!--          <img :src="item2.img" alt="" >-->
            <!--          </div>-->
            <!--          <i class="el-icon-arrow-right"></i>-->
            <!--        </div>-->
            <div class="small-box">

              <i class="el-icon-arrow-left " @click="variationsOnThePreviousPage"
                 :class="{'disabledShow':variantsShow}"></i>

              <div class="small-img-box">
                <div class="small-img" :style="{'transform': `translateX(${variantsPx}px)`}"
                     :class="{'show':variantsImgIndex === index2}" v-for="(item2,index2) in variantInfo.img"
                     :key="index2" @click="variantImg(item2.img400,index2)">
                  <el-image :src="item2.img400" alt="" style="width: 100%;height: 100%;">
                    <div slot="error" class="image-slot" style="width: 100%;height: 100%;">
                      <img :src="url" alt="" style="width: 100%;height: 100%;">
                    </div>
                  </el-image>
                </div>
              </div>

              <i class="el-icon-arrow-right" @click="variantsNext" :class="{'disabledShow':variantsShowNext}"></i>

            </div>
            <div class="specs">
              <span>规格</span>
              <div class="size" v-for="(item3, index3) in variantInfo.design_item" :key="index3">{{ item3.size_name }}
              </div>
            </div>
            <div class="color">
              <span>颜色</span>
              <div class="colorbox" v-for="(item1,index1) in DesignVariant" :key="index1"
                   :style="{background:`#${item1.color}`}" @click="colorV(index1)">
              </div>
            </div>
          </div>


        </div >
        <div class="right" v-if="!compileLoading">
          <el-form :model="DesignMatrix" :rules="rules" ref="matrixForm" label-width="80px" class="demo-ruleForm"
                   size="small">
            <el-form-item label="ID" prop="matrixId">
              <el-input v-model="DesignMatrix.code" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="导出名称" prop="export_name">
              <el-input v-model="DesignMatrix.export_name" @change="designExport"></el-input>
            </el-form-item>
            <el-form-item label="标签" prop="label">
              <el-input v-model="DesignMatrix.tags"></el-input>
              <!-- <el-button class="use-btn">应用到变体</el-button> -->
            </el-form-item>
          </el-form>
          <!-- 变体 -->
          <div class="variant-form" v-if="variantInfo">
            <el-form :model="variantInfo" :rules="rules" ref="variantForm" label-width="80px" class="demo-ruleForm"
                     size="small">
              <el-form-item label="ID" prop="variantId">
                <el-input v-model="variantInfo.id" :disabled="true" class="width240"></el-input>
                <el-button type="primary" class="use-btn" @click="setParent"
                           :disabled="variantInfo.img === DesignMatrix.img">设为母体
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <span slot="footer" v-if="!compileLoading">
        <el-button @click="upload">取 消</el-button>
        <el-button type="primary" @click="uploadSure">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {editDesignInfo} from '@/api/design'
import {mapState, mapMutations} from 'vuex'
import magnifier from '@/assets/icon/magnifying.png'
import moren from '@/assets/error/imgNo.png'

export default {
  props: {
    EditShow: {
      type: Boolean
    },
    DesignMatrix: {
      type: Object
    },
    DesignVariant: {
      type: Array
    },
    designMatrixImg: {
      type: String
    },
    designMatrixImgList: {
      type: Array
    },
    designId: {
      type: Number
    },
    compileLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      url: moren,
      disabledShow: true,
      variantsShow: true,
      variantsShowNext: false,
      disabledShowNext: false,
      dialogVisible: false,
      // 母体展示图路径
      matrixImgUrl: '',
      // 变体图片展示路径
      variantImgUrl: '',
      // 变体单个信息
      variantInfo: null,
      // 母体Id
      matrixId: null,
      // 变体Id
      variantId: null,
      // 导出名称
      exportName: null,
      matrixImgIndex: 0,
      variantsImgIndex: 0,
      variantsPx: 0,
      design_matrix: {
        "exportName": null,
        'variantId': null
      },
      variantList: [],    //变体大图保存
      rules: {
        id: [
          {required: true, message: 'ID不能为空', trigger: 'blur'},
        ],
        design_id: [
          {required: true, message: 'ID不能为空', trigger: 'blur'},
        ],
        export_name: [
          {required: true, message: '名称不能为空', trigger: 'blur'}
        ],
      },
      enlarge: magnifier,  //放大镜
      translatePx: 0
    }
  },
  created() {

  },
  mounted() {
  },
  beforeCreate() {

  },
  beforeUpdate() {
  },
  beforeMount() {

  },

  computed: {
    ...mapState(['variantInformation'])
  },
  methods: {

    //变体上一页
    variationsOnThePreviousPage() {
      if (this.variantsPx === 0) {
        this.variantsShow = true
      } else {
        this.variantsPx += 54
        this.variantsShowNext = false
      }
    },
    //变体下一页
    variantsNext() {
      if (Math.abs(this.variantsPx) < this.variantInfo.img.length * 54 - (54 * 4)) {
        this.variantsPx -= 54
        this.variantsShow = false
      } else {
        this.variantsShowNext = true
      }
    },
    // 展示母体图片
    matrixImg(val, index) {
      this.matrixImgIndex = index
      this.matrixImgUrl = val
    },
    //上一张
    previous() {
      if (this.translatePx === 0) {
        this.disabledShow = true
      } else {
        this.translatePx += 54
        this.disabledShowNext = false
      }
    },

    //下一章
    next() {
      if (Math.abs(this.translatePx) < this.DesignMatrix.img.length * 54 - (54 * 4)) {
        this.translatePx -= 54
        this.disabledShow = false
      } else {
        this.disabledShowNext = true
      }
    },


    ...mapMutations([
      'moreChangeable'
    ]),
    handleClose(done) {
      // this.upload()
      this.matrixImgUrl = ''
      this.variantImgUrl = ''
      this.translatePx = 0
      this.disabledShowNext = false
      this.disabledShow = true
      this.variantsPx = 0
      this.variantsShow = true
      this.variantsShowNext = false
      this.matrixImgIndex = 0
      this.variantsImgIndex = 0
      this.$emit('closeEdit')
    },
    upload() {
      this.dialogVisible = false
      this.$emit('uplode', this.dialogVisible)
    },
    // 默认展示图片
    // default(i){
    //   this.matrixImgUrl = this.DesignMatrix.imgThumb[i].img
    //   // console.log(1);
    //   // this.colorV(0)
    // },

    colorV(index) {
      // this.variantInfo = this.DesignVariant[index]
      this.moreChangeable(this.DesignVariant[index])

    },
    // 展示变体图片
    variantImg(val, i) {
      this.variantImgUrl = val
      this.variantsImgIndex = i
    },
    // 设置母体
    setParent() {

      this.variantId = this.variantInfo.id

    },
    // 导出名称
    designExport() {

      this.matrixId = this.DesignMatrix.id
      this.exportName = this.DesignMatrix.export_name
    },
    //展示大图
    bigPicture() {
      // let matrix = this.matrixImgUrl
      // let design = this.designMatrixImg
      //  matrix = matrix ? matrix : design

      this.variantList = []
      for (let i = 0; i < this.variantInfo.img.length; i++) {
        this.variantList.push(this.variantInfo.img[i].img)
      }
    },
    // 保存
    async uploadSure() {
      this.design_matrix.exportName = this.exportName
      this.design_matrix.variantId = this.variantId
      try {
        const {message} = await editDesignInfo({
          designMatrix: this.design_matrix,
          designId: this.designId
        })
        this.$message({
          message,
          type: 'success'
        });
        this.upload()
      } catch (e) {
        this.$message.error(e)
        console.log(e);
      }
    },
  },
  watch: {
    variantInformation() {
      this.variantInfo = this.variantInformation
      this.variantImgUrl = this.variantInformation.img[0].img400
    }
  },

}
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-top: 5vh !important;
}

/deep/ .el-dialog__header {
  border-bottom: 1px solid #D8D8D8;
  color: #333;
  font-size: 18px;
}

.el-input {
  width: 500px;
}

.el-textarea {
  width: 500px;
}

.el-select {
  width: 500px;
}

.compile-box {
  display: flex;
  height: 643px;
  overflow: auto;

  .left {
    width: 280px;

    .matrix {
      .matrix-title {
        font-size: 16px;
        color: #3A3A3A;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .matrix-img {
        width: 280px;
        height: 280px;
        border: 1px solid #999;
        position: relative;

        .icon_enlarge {
          width: 16px;
          height: 16px;
          border: 1px solid #000;
          background: #000;
          position: absolute;
          left: 5px;
          top: 5px;
          z-index: 5;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

    }

    .variant {
      margin-top: 27px;

      .variant-title {
        font-size: 16px;
        color: #3A3A3A;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .variant-img {
        width: 280px;
        height: 280px;
        border: 1px solid #999;

        img {
          width: 100%;
          height: 100%;

        }
      }

      .variant-box {
        display: flex;
        justify-content: space-around;
        font-size: 18px;
        height: 45px;
        align-items: center;
        margin-top: 6px;

        i {
          cursor: pointer;
        }

        .small-variant {
          width: 45px;
          height: 45px;
          border: 1px solid #666;

          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }

  .specs {
    margin-top: 8px;

    span {
      display: inline-block;
      margin-right: 10px;
    }

    .size {
      display: inline-block;
      width: 48px;
      height: 18px;
      text-align: center;
      overflow: hidden;
      line-height: 18px;
      border: 1px solid #999999;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .color {
    margin-top: 8px;

    .colorbox {
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-left: 10px;
      background: chartreuse;
      cursor: pointer;
      border: 1px solid #000;
    }
  }

  .right {
    margin-top: 60px;
    margin-left: 80px;

    .el-input {
      width: 350px;
    }

    .el-select {
      width: 350px;
    }

    .use-btn {
      width: 98px;
      //background: #FA4A14;
      color: #fff;
      margin-left: 15px;
    }
  }

  .variant-form {
    margin-top: 245px;
  }

  .color-btn {
    width: 80px;
    background: #EEEEEE;
    border-radius: 2px 0px 0px 2px;
  }

  .width240 {
    width: 240px !important;
  }

  .width270 {
    width: 270px !important;
  }
}

.compile-box::-webkit-scrollbar {
  width: 10px;
}

.show {
  opacity: 1 !important;
}

.disabledShow {
  opacity: .4;
  cursor: no-drop !important;
}

.small-box {
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  height: 45px;
  align-items: center;
  margin-top: 6px;

  .small-img-box {
    display: flex;
    //flex-wrap: no-warp;

    width: 225px;
    height: 45px;
    overflow: hidden;

  }

  i {
    cursor: pointer;
  }

  .small-img {
    flex-shrink: 0;
    display: flex;
    //display: inline-block;
    width: 45px;
    height: 45px;
    border: 1px solid #666;
    margin: 0 5px;
    opacity: .4;
    transition: all .3s linear;

    .el-image {
      width: 100%;
      height: 100%;
      cursor: pointer;

    }
  }
}
</style>
