<template>
  <div>
    <!--    <productExport :exportPop="exportPop" @close="closeExport" :designId="designId" @update="update"></productExport>-->


    <div class="operation-column">
      <div class="left">
        <!--        <el-button size="mini" class="remit" @click="productExport">汇出</el-button>-->
        <el-select v-model="batchValue" class="batch-operation" size="mini" placeholder="批量操作" @change="changeBatch">
          <el-option
              v-for="(item,index) in batchOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <!--        <span class="account_text">账号</span>-->
        <!--        <el-select v-model="accountNumber" clearable @change="subAccountChange" class="batch" style="width: 150px"-->
        <!--                   size="mini">-->
        <!--          <el-option-->
        <!--              v-for="item in allSubAccount"-->
        <!--              :key="item.id"-->
        <!--              :label="item.phone"-->
        <!--              :value="item.code">-->
        <!--          </el-option>-->
        <!--        </el-select>-->

      </div>
      <div class="right">
        <div class="search">
          <el-input
              size="mini"
              placeholder="名称/编号"
              v-model="inputSearch"
              class="search-inp"
              @keyup.enter.native="nameCode"
          >
          </el-input>
          <el-button icon="el-icon-search" size="mini" class="search-btn" @click="nameCode"></el-button>
        </div>
      </div>
    </div>
    <div class="list">
      <!-- 编辑弹窗 -->
      <!-- <compile :EditShow="editShow" @uplode="editshow"/> -->
      <!-- 表头 -->
      <div class="title">
        <div>
          <el-row class="titleflex">
            <el-col :span="1">
              <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">
              </el-checkbox>
            </el-col>
            <el-col :span="1">
              <div class="grid-content bg-purple-light">设计ID</div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple">产品图片</div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">产品信息</div>
            </el-col>
            <!--            <el-col :span="3">-->
            <!--              <div class="grid-content bg-purple ">所属公司</div>-->
            <!--            </el-col>-->
            <el-col :span="2">
              <div class="grid-content bg-purple-light">出单数量</div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple">类别</div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple">产品类型</div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple-light">标签</div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">合成时间</div>
            </el-col>
            <el-col :span="2">
              <div class="grid-content bg-purple-light">操作</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 主体 -->
      <div class="list-body">
        <el-row></el-row>
        <!--        opacity-->
        <el-row class="commodityList" v-for="(item,index) in ProductList"
                :class="[{'transparent':item.status !== 1},{opacity:!setStatus(item)}]"
                :key="item.id">
          <div class="shadow" v-if="item.status !== 1">失效</div>
          <el-col :span="1">
            <el-checkbox-group :disabled="!setStatus(item)" v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox :disabled="item.status !== 1" :label="item.id"></el-checkbox>
            </el-checkbox-group>
          </el-col>
          <el-col :span="1" >
            <div style="margin-top: 17px;">{{ item.id }}</div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple img" v-if="item.img.length > 0">
              <div class="img-box" v-for="(item1,index1) in item.img " :key="index1">
                <el-image :src="item1.img400" >
                  <div slot="error" class="image-slot">
                    <el-image :src="url" alt="" />
                  </div>
                </el-image>
              </div>
            </div>
            <div class="grid-content bg-purple img" v-else>
              <div class="img-box" v-for="(item1,index2) in 4 " :key="index2">
                <el-image :src="compositing" alt=""></el-image>
              </div>
            </div>
            <!-- <div class="variant-img">
                <img src="@/assets/yifu.png" alt="">
            </div> -->
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple-light">
              <div class="distance8"><span>产品名称：</span><span class="text">{{ item.name }}</span></div>
              <!-- <div class="distance8"><span>产品分类：</span><span class="text">{{item.theme ? item.theme.name : '无'}}</span></div> -->
<!--              <div class="distance8"><span>导出名称：</span><span class="text">{{-->
<!--                  item.export_name ? item.export_name : '自定义'-->
<!--                }}</span></div>-->
              <div class="distance8"><span>创建者：</span><span class="text">{{!!item.manager && !!item.manager.phone ?  item.manager.phone : '普通用户'}}</span></div>
              <div class="distance8"><span>合成编号：</span><span class="text">{{ item.code }}</span></div>
<!--              <div class="distance8"><span>成品编号：</span><span class="text">{{ item.code }}</span></div>-->
            </div>
          </el-col>
          <!--          <el-col :span="3">-->
          <!--            <div class="grid-content bg-purple">{{-->
          <!--                item.user.merchant.code !== 'null' ? item.user.merchant.code : '暂无'-->
          <!--              }}-->
          <!--            </div>-->
          <!--          </el-col>-->
          <el-col :span="2">
            <div class="grid-content bg-purple-light">{{ item.sold }}</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content bg-purple">{{ item.theme ? item.theme.name : '无' }}</div>
          </el-col>
          <el-col :span="2">
            <div>{{setProductType(item.type)}}</div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content bg-purple-light">{{ item.tags }}</div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content bg-purple-light">
              <div>{{ item.created_at }}</div>
              <!-- <div>14:03:04</div>
              <div>15976887004</div> -->
            </div>
          </el-col>
          <el-col :span='2' v-if="item.status === 1">
            <div class="grid-content bg-purple-light">
              <div class="configuration">
                <!--              <div @click="operation(0)">下载</div>-->
                <div @click="download(item)" v-show="setStatus(item)">下载</div>
                <div @click="categorize(item.id)" v-show="setStatus(item)">归类</div>
                <div @click="edit(item.id)" v-show="setStatus(item)">编辑</div>
                <div @click="operation(item.id)" v-show="setStatus(item)">加入购物车</div>
                <div @click="remove(item.id)">删除</div>
                <div @click="redesign(item)" v-show="Number(item.type) === 3 && setStatus(item)">重新设计</div>
              </div>
            </div>
          </el-col>
          <div @click="remove(item.id)" v-else style="cursor: pointer">删除</div>

        </el-row>

      </div>
      <!--      暂无产品数据-->
      <div v-if="ProductList.length <= 0  || !ProductList || !Array.isArray(ProductList)">
        <div class="noData">
          <span>暂无产品数据，</span>
          <span class="goToDesign" @click="design">前往设计</span>
        </div>
      </div>
      <!-- 分页 -->
      <!-- <gallery-foot/> -->

    </div>
    <add-shop-cart
        :Operation="operationShow"
        :title="title"
        :shopId="shopId"
        @showShop="showShop"
        :downloadId="downloadId"
    />


  </div>
</template>

<script>

import GalleryFoot from '../../../mapDepot/allGalleries/components/GalleryFoot.vue';
import AddShopCart from './AddShopCart.vue';
import Compile from './compile.vue';
import productExport from "@/views/design/myProducts/components/productExport";
import {getCartList} from '@/api/design'
import {getASubAccount} from "@/api/login";
import {exportIsProductSimilar} from '@/api/productTemplate'
import moren from '@/assets/moren.png'
import compositing from '@/assets/compositing.png'
import axios from 'axios'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import Cookies from "js-cookie";


export default {
  components: {GalleryFoot, Compile, AddShopCart, productExport},
  props: {
    ProductList: {
      type: Array
    }
  },
  data() {
    return {
      compositing,
      checkAll: false, //头部复选框
      isIndeterminate: false, //全选控制
      checkedCities: [], //选择的商品
      cities: [], //全部商品
      editShow: false,    //控制编辑弹窗
      title: null,
      operationShow: false,  //控制操作弹窗
      shopId: null,     //购物车
      downloadId: null,//下载
      // 批量操作
      batchOptions: [{
        value: 1,
        label: '批量归类'
      },
        {
          value: 2,
          label: '下载'
        },
        {
          value: 3,
          label: '删除'
        },
      ],
      // 批量操作值
      batchValue: '',
      // 搜索框值
      inputSearch: '',

      exportPop: false,   //汇出弹窗
      designId: null,
      allSubAccount: [],//子账号
      accountNumber: '',
      isProductSimilar: false,   //判断是否是同类目产品
      url: moren
    }
  },
  created() {

  },
  mounted() {
    // this.getASubAccount()
  },
  methods: {
    //重新设计
    redesign(item) {
      console.log(item)
      // productId 产品id
      // merchantId 商户id
      //  designId 设计id
      console.log(this.$store.state.userInfo.merchant[0].id)

      const {id: designId, type, merchant_id: merchantId, product_id: productId} = item






      if (Number(type) === 3) {


        let token

        if (process.env.VUE_APP_CUSTOM_ENV === 'production' || process.env.VUE_APP_CUSTOM_ENV === 'test') {

          token = Cookies.get('token')

        } else {

          token = process.env.VUE_APP_TOKEN

        }
        let designUrl = process.env.VUE_APP_TEMPLATE_DESIGNER_URL
        let url = `${designUrl}?token=${token}&productId=${productId}&merchantId=${merchantId}&designId=${designId}`
        console.log(url)
        window.open(url)
      }
    },

    design() {
      let token

      if (process.env.VUE_APP_CUSTOM_ENV === 'production' || process.env.VUE_APP_CUSTOM_ENV === 'test') {

        token = Cookies.get('token')

      } else {

        token = process.env.VUE_APP_TOKEN

      }

      let path = encodeURIComponent(window.location.href)

      location.href = (`${process.env.VUE_APP_DESIGNER_URL}?token=${token}&redirect=${path}`)
    },

    //关闭购物车
    showShop() {
      this.shopId = null
      this.operationShow = false
      this.downloadId = null
    },


    //下载
    download(item) {
      this.operationShow = true
      this.downloadId = item.id
      this.title = '下载'
    },


    checkColse() {
      this.checkedCities = []
      this.checkAll = false //头部复选框
      this.isIndeterminate = false //全选控制
    },

    //region下载
    // download(item) {
    //   console.log(item)
    //   let imgList = []
    //   if (Array.isArray(item) && item.length > 0 && !!item) {
    //     //是数组
    //     item.forEach(v => {
    //       v.imgThumb.forEach(e => {
    //         imgList.push(e.img)
    //       })
    //     })
    //   } else {
    //     //不是数组
    //     if (!!item) {
    //       item.imgThumb.forEach(val => {
    //         imgList.push(val.img)
    //       })
    //     }
    //   }
    //
    //
    //   const zip = new JSZip()
    //   const cache = {}
    //   const promises = []
    //   imgList.forEach(item => {
    //     const promise = this.getFile(item).then(data => {
    //       const arr_name = item.split('/') // 下载文件, 并存成ArrayBuffer对象
    //       const file_name = arr_name[arr_name.length - 1] // 获取文件名
    //       zip.file(file_name, data, {binary: true}) // 逐个添加文件
    //       cache[file_name] = data
    //     })
    //     promises.push(promise)
    //   })
    //   Promise.all(promises).then(() => {
    //     zip.generateAsync({type: 'blob'}).then(content => {
    //       // 生成二进制流
    //       FileSaver.saveAs(content, '文件下载.zip') // 利用file-saver保存文件  自定义文件名
    //     })
    //   })
    //
    //
    //     },


    // getFile(url) {
    //   return new Promise((resolve, reject) => {
    //     let obj = {
    //       method: 'get',
    //       url,
    //       // responseType: 'blob'
    //       responseType: 'arraybuffer'
    //     }
    //     axios(obj)
    //         .then(data => {
    //           resolve(data.data)
    //         })
    //         .catch(error => {
    //           reject(error.toString())
    //         }).finally(() => {
    //           this.checkedCities = []
    //           this.checkAll = false //头部复选框
    //           this.isIndeterminate = false //全选控制
    //     })
    //   })
    //
    // },
    //endregion
    update() {
      this.getCartList()
      this.checkAll = false //头部复选框this.
      this.isIndeterminate = false //全选控制
      this.checkedCities = [] //选择的商品
      this.cities = [] //全部商品
    },

    //获取子账号
    async getASubAccount() {
      try {
        const data = await getASubAccount()
        this.allSubAccount = data?.data
      } catch (e) {
        this.$message.error(e)
      }
    },

    //账号下拉框
    subAccountChange(e) {
      this.$emit('subAccountChange', e)
    },
    // 全选框
    handleCheckAllChange(val) {
      let arr = []
      this.ProductList.forEach(e => {
        if (!!e.img && e.img.length > 0 && Array.isArray(e.img)) {
          arr.push(e.id)

        }
      });
      this.checkedCities = val ? arr : []
      this.isIndeterminate = false
      this.$emit('All', this.checkedCities)
    },
    // 单选
    handleCheckedCitiesChange(val) {
      var arr = []
      this.ProductList.forEach(e => {
        arr.push(e.id)
      });
      this.checkAll = val.length === arr.length
      this.isIndeterminate = val.length > 0 && val.length < arr.length
      this.$emit('single', this.checkedCities)
    },
    // 搜索
    nameCode() {
      this.$emit('namecode', this.inputSearch)
    },
    // 批量操作
    changeBatch() {
      if (Array.isArray(this.checkedCities) && this.checkedCities.length > 0 && !!this.checkedCities) {
        if (this.batchValue === 3) {
          //删除

          this.$emit('removeId', this.checkedCities)
          this.checkedCities = []
          this.checkAll = false //头部复选框
          this.isIndeterminate = false //全选控制


        } else if (this.batchValue === 2) {

          this.operationShow = true
          this.downloadId = this.checkedCities
          this.title = '下载'
        } else {
          //归类
          this.$emit('batch')
        }

      } else {
        this.$message.error('请先选择产品')
      }
      this.batchValue = null
    },
    suer() {
      let id = this.checkedCities
      if (this.checkedCities === null) {
        this.handleClose()
      } else {
        this.$emit('removeId', id)
        this.handleClose()
      }
    },
    // 删除
    remove(id) {
      this.$emit('removeId', id)
    },
    // 编辑
    edit(id) {
      this.$emit('edit', id)
    },
    // 控制下载和加入购物车弹窗
    operation(shopId) {
      this.operationShow = true
      this.shopId = shopId
      this.title = '购物车'
    },

    // 购物车列表
    async getCartList() {
      try {
        const {data} = await getCartList(
            {
              design_id: this.shopId
            }
        )
        this.shopList = data
      } catch (e) {
        this.$message.error(e)
      }

    },
    //归类
    categorize(id) {
      this.$emit('batch', id)
    },
    //  判断产品是否是同类目
    async exportIsProductSimilar() {
      try {
        const {data} = await exportIsProductSimilar(
            {
              design_id: this.checkedCities
            }
        )
        this.isProductSimilar = data.IsProductSimilar
        if (this.isProductSimilar) {
          this.exportPop = true
          this.designId = this.checkedCities
        }
      } catch (e) {
        this.$message({
          message: e,
          type: 'warning'
        });
      }
    },
    //  产品汇出
    productExport() {
      if (this.checkedCities.length !== 0) {
        this.exportIsProductSimilar()
      } else {
        this.$message({
          message: '请选择要汇出的产品',
          type: 'warning'
        });
      }
    },
    //  关闭弹窗
    closeExport(val) {
      this.exportPop = val
    }
  },
  computed: {
    setStatus() {
      return (item) => {
        return Array.isArray(item.img) && item.img.length > 0 && !!item.img
      }
    },
    setProductType(){
      // design.type: 1=标品，2=系统模板，3=定制产品，4=用户成品   只有3支持重新设计。
      return (type) => {
       let val =  Number(type)
        if(val === 1) {
          return '标品'
        } else if(val === 2) {
          return '系统模板'
        } else if(val === 3) {
          return '定制产品'
        } else if (val ===4) {
          return '用户成品'
        } else {
          return '未知'
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-col {
  border: 1px solid transparent;
}
.noData {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 600;

  .goToDesign {
    color: #FA4A14;
    cursor: pointer;
  }
}

.opacity {
  opacity: .5;

}

.list {
  // width: 1650px;
  // min-width: 1200px;
  font-size: 12px;
}

.distance8 {
  margin-bottom: 8px;
  color: #333;
  font-size: 12px;
}

.text {
  color: #999;
}

.center {
  text-align: center;
}

.img {
  display: flex;
  flex-wrap: wrap;
  padding-right: 20px;

  .img-box {
    width: 60px;
    height: 60px;
    margin-right: 3px;
    margin-bottom: 3px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

/deep/ .el-checkbox__label {
  color: rgba(0, 0, 0, 0) !important;
}

.variant-img {
  cursor: pointer;
  width: 30px;
  height: 30px;

  img {
    width: 100%;
    height: 100%;

  }
}

.account_text {
  font-size: 14px;
  color: #999;
  line-height: 28px;
  margin: 0 10px;
}

.title {
  display: block;
  height: 50px;
  background: #F7F7F7;
  // margin: 0 auto;
  line-height: 50px;
  font-size: 15px;
  // width: 1650px;
  // display: flex;
  // justify-content: space-between;
  color: #333333;
  font-weight: 600;
}

/deep/ .el-checkbox__inner {
  margin: 18px 0 18px 9px;
  border: 1px solid #666666;
  border-radius: 2px;
}

.dispalyflex {
  display: flex;
  align-items: center;

  .clothingSize {
    height: 17px;
    font-weight: 400;
    color: #999999;
    line-height: 17px
  }
}

.center {
  text-align: center;
}

.commodityList {
  padding-top: 40px;
  padding-bottom: 30px;
  // width: 1650px;
  overflow-x: hidden;
  border-bottom: 1px solid #E9E9E9;

  .shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 50px;
    border: 1px dashed #cccccc;
    transform: translate(-50%, -50%) rotate(10deg);
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    color: #cccccc;
    font-weight: 700;
  }

  .configuration {
    font-size: 14px;
    cursor: pointer;

    & > div:hover {
      color: #FA4A14;
    }
  }

  &:hover {
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.29);
    border-radius: 5px;
  }

  &:hover .isShoW {
    display: inline-block;
    cursor: pointer;
  }
}

.operation-column {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;

  .left {
    display: flex;
  }

  .remit {
    background: #FA4A14;
    color: #fff;
  }

  .batch-operation {
    width: 100px;
    height: 26px;
    margin-left: 20px;
  }

  .right {
    width: 412px;
    height: 26px;
    border: 1px solid #999999;

    .search {
      display: flex;
      justify-content: space-between;

      .search-inp {
        width: 370px;
        height: 24px;

        /deep/ input {
          border: 0;
          height: 100%;
        }
      }

      .search-btn {
        font-size: 14px;
        height: 24px;
        border: 0;
        color: #000;
      }
    }
  }
}

.transparent {
  opacity: .4;
}
</style>
