<template>
  <div class="sort-compile">
    <el-dialog
        title="创建类别"
        :visible.sync="Fshow"
        width="30%"
        :before-close="close"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="60px" class="demo-ruleForm" size="small">
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="describe">
          <el-input type="textarea" v-model="ruleForm.describe"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button type="primary" @click="sure">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    Fshow: {
      type: Boolean
    }
  },
  data() {
    return {
      ruleForm: {
        name: '',
        describe: ''
      },
      rules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        describe: [
          {required: true, message: '请输入描述', trigger: 'blur'},
        ]
      },
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    close() {
      this.dialogVisible = false
      this.$emit('comShow', this.dialogVisible)
      this.ruleForm.name = ''
      this.ruleForm.describe = ''
    },
    sure() {
      if (this.ruleForm.name !== '' && this.ruleForm.describe !== '') {
        this.$emit('ruleform', this.ruleForm)
        this.close()
        this.ruleForm.name = ''
        this.ruleForm.describe = ''
        // console.log(this.ruleForm);
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>
